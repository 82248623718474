import { Routes, Route } from "react-router-dom";
import VeracityHome from "../pages/veracity/VeracityHome";

const VeracityRoutes = () => (
  <Routes>
    <Route path="/" element={<VeracityHome />} />
  </Routes>
);

export default VeracityRoutes;
