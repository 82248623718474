import React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Grid, Badge, Typography } from "@mui/material";

const planColumns: GridColDef[] = [
  { field: "name", headerName: "Plan", width: 200 },
  { field: "amount", headerName: "Amount", width: 150 },
  {
    field: "ytdSpend",
    headerName: "YTD Spend",
    width: 150,
    align: "right",
    headerAlign: "right",
  },
];

const planRows: GridRowsProp = [
  {
    id: 1,
    name: "Individual Deductible",
    amount: "$2500.00",
    ytdSpend: "$800.00",
  },
  {
    id: 2,
    name: "Family Deductible",
    amount: "$5000.00",
    ytdSpend: "$1220.00",
  },
  { id: 3, name: "CoPay", amount: "$25.00", ytdSpend: "$25.00" },
  {
    id: 4,
    name: "Max Out Of Pocket",
    amount: "$7500.00",
    ytdSpend: "$2550.00",
  },
];

const coverageColumns: GridColDef[] = [
  { field: "name", headerName: "Coverage Info", width: 200 },
  {
    field: "value",
    headerName: "Value",
    width: 150,
    align: "right",
    headerAlign: "right",
  },
];

const coverageRows: GridRowsProp = [
  {
    id: 1,
    name: "coverageType",
    value: "Family",
  },
  { id: 2, name: "employeeNumber", value: "1234567" },
  { id: 3, name: "groupNumber", value: "1234567" },
  { id: 4, name: "planNumber", value: "1234567" },
];

export const MyHealthPlanGrid: React.FC = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid rows={planRows} columns={planColumns} hideFooter />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              rows={coverageRows}
              columns={coverageColumns}
              hideFooter
            />
          </div>
        </Grid>
      </Grid>
      <Typography fontSize={10} fontWeight={"800"} ml={2} mt={2} mb={2}>
        Co-Pay Applies to all non-emergency visits & service encounters
      </Typography>
    </div>
  );
};
