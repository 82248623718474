import { SearchOutlined } from "@mui/icons-material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import { useInitial } from "../../InitialContext";

export const DashboardQuickCards: React.FC = () => {
  const { initialData } = useInitial();
  return (
    <Grid pt={2} container spacing={2}>
      <Grid item xs={12} md={3}>
        <Card style={{ minHeight: "175px" }}>
          <CardContent>
            <Typography
              gutterBottom
              sx={{ color: "text.secondary", fontSize: 14 }}
            >
              Reporting Plans Connected
            </Typography>
            <Typography variant="h5" component="div">
              {initialData ? (
                initialData.reporting_plan_count
              ) : (
                <CircularProgress size={10} />
              )}
            </Typography>
            <Typography fontSize={10} sx={{ color: "text.secondary", mb: 1.5 }}>
              last 30 days
            </Typography>
            <SparkLineChart
              data={[1, 4, 2, 5, 7, 2, 4, 6]}
              height={30}
              plotType="bar"
              colors={["#1565c0"]}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card style={{ minHeight: "175px" }}>
          <CardContent>
            <Typography
              gutterBottom
              sx={{ color: "text.secondary", fontSize: 14 }}
            >
              Employer Reporting Plans
            </Typography>
            <Typography variant="h5" component="div">
              125,457
            </Typography>
            <Typography fontSize={10} sx={{ color: "text.secondary", mb: 1.5 }}>
              last 30 days
            </Typography>
            <SparkLineChart
              data={[1, 4, 2, 5, 7, 2, 4, 6]}
              height={30}
              plotType="bar"
              colors={["#1565c0"]}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card style={{ minHeight: "175px" }}>
          <CardContent>
            <Typography
              gutterBottom
              sx={{ color: "text.secondary", fontSize: 14 }}
            >
              In Network Prices
            </Typography>
            <Typography variant="h5" component="div">
              3,567,932
            </Typography>
            <Typography fontSize={10} sx={{ color: "text.secondary", mb: 1.5 }}>
              last 30 days
            </Typography>
            <SparkLineChart
              data={[1, 4, 2, 5, 7, 2, 4, 6]}
              height={30}
              plotType="bar"
              colors={["#1565c0"]}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card
          style={{ minHeight: "175px" }}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200] // Slightly darker than white in light mode
                : theme.palette.grey[800], // Slightly lighter than black in dark mode
          }}
        >
          <CardContent>
            <SearchOutlined />
            <Typography
              gutterBottom
              sx={{ color: "text.secondary", fontSize: 16 }}
            >
              Explore More
            </Typography>
            <Typography
              gutterBottom
              sx={{ color: "text.secondary", fontSize: 14 }}
            >
              Search providers, plans, and more
            </Typography>
            <Button variant="contained" color="primary">
              Get Insights
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
