import {
  Container,
  Box,
  Breadcrumbs,
  Typography,
  CircularProgress,
  CardContent,
  Card,
  Grid,
} from "@mui/material";
import React from "react";

const Integrations: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  return (
    <Container maxWidth={false} disableGutters>
      <Box pl={2} pt={2} mb={2}>
        <Breadcrumbs>
          <Typography>Dashboard</Typography>
          <Typography>Integrations</Typography>
        </Breadcrumbs>
        {loading && (
          <Box mt={3}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Grid pt={2} pl={2} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                NPPES Provider Index
              </Typography>
              <Typography variant="body2" color="text.secondary">
                learn more...
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Integrations;
