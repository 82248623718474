import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useSearch } from "../SearchContext";

export function BasicSearch() {
  const { searchQuery, addSearchQuery, setSearchQuery } = useSearch();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      addSearchQuery(searchQuery);
    }
  };
  return (
    <React.Fragment>
      <Tooltip title="Search" enterDelay={1000}>
        <div>
          <IconButton
            type="button"
            aria-label="search"
            sx={{
              display: { xs: "none", md: "none" },
            }}
          >
            <SearchIcon />
          </IconButton>
        </div>
      </Tooltip>
      <TextField
        label={null}
        fullWidth
        variant="outlined"
        size="small"
        value={searchQuery} // Bind the value to the context
        onChange={handleSearchChange} // Handle changes
        onKeyDown={handleKeyDown} // Listen for Enter key
        slotProps={{
          input: {
            endAdornment: (
              <IconButton type="button" aria-label="search" size="small">
                <SearchIcon />
              </IconButton>
            ),
            sx: { pr: 0.5 },
          },
        }}
        sx={{
          mr: 1,
          width: { xs: "100%", md: "100%" },
        }}
      />
    </React.Fragment>
  );
}
