import React, { useState } from "react";
import { CloseSharp } from "@mui/icons-material";
import {
  Grid,
  ButtonGroup,
  Button,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  styled,
} from "@mui/material";

export const VeracityExcelPlugin: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          ml: "auto",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <ButtonGroup>
          {/* <Button variant="outlined">Download Results</Button> */}
          <Button variant="outlined" onClick={() => setOpen(true)}>
            Excel Integration
          </Button>
        </ButtonGroup>
      </Grid>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Excel Integration</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseSharp />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Paste the following VBA code into your Excel workbook to fetch &
            search data. Contact your rep for API key credentials.
          </Typography>
          <Typography gutterBottom>
            <pre style={{ width: "90%", display: "block", fontSize: 12 }}>
              {`
Sub FetchAPIData() 
  Dim Http As Object 
  Set Http = CreateObject("MSXML2.XMLHTTP.6.0") 

  Dim url As String 
  url = "https://yourapi.com/api/search/in-network-v1/keyword_search?keyword=" & Range("A1").Value 

  Http.Open "GET", url, False 
  Http.Send 

  Dim response As String 
  response = Http.responseText 

  ' Parse and write response data to cells, e.g., starting at B2 
  Range("B2").Value = response ' Simplified for example
End Sub`}
            </pre>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Learn More
          </Button>
          <Button autoFocus onClick={handleClose}>
            Download
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
