// ShopperRoutes.tsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import ShopperPage from "../pages/shopper/Shopper";
import CartPage from "../pages/shopper/CartPage";
import MyChartPage from "../pages/shopper/MyChartPage";
import ShopPage from "../pages/shopper/ShopPage";

const ShopperRoutes = () => (
  <Routes>
    <Route path="/" element={<ShopperPage />} />
    <Route path="/cart" element={<CartPage />} />
    <Route path="/shop" element={<ShopPage />} />
    <Route path="/my_chart" element={<MyChartPage />} />
  </Routes>
);

export default ShopperRoutes;
