import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { OpenSearchInNetworkRecord } from "../../interfaces";

interface Message {
  sender: "user" | "bot";
  text: string;
}
interface BotResponse {
  modelResponse: {
    content: [
      {
        text: string;
        type: string;
      },
    ];
  };
  id: string;
  model: string;
  role: string;
  stop_reason: string;
  stop_sequence: string;
  type: string;
  usage: {
    input_tokens: number;
    output_tokens: number;
  };
}

interface PricingChatBotProps {
  selectedRecord: OpenSearchInNetworkRecord | null;
  open: boolean;
  onClose: () => void;
}

const PricingChatBot: React.FC<PricingChatBotProps> = ({ selectedRecord }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [botResponse, setBotResponse] = useState<BotResponse | null>(null);
  const [input, setInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const apiEndpoint = `${window.location.origin}/api/claude`; // Replace with your API endpoint

  const sendMessage = async () => {
    if (!input.trim()) return;

    const userMessage: OpenSearchInNetworkRecord | null = selectedRecord;
    // setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsLoading(true);
    if (!userMessage) {
      const errorMessage: Message = {
        sender: "bot",
        text: "Sorry, something went wrong. Please try again later.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
      setIsLoading(false);
      return;
    }
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userMessage),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      const botMessage: BotResponse = data;
      setBotResponse(botMessage);
      //   setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Failed to fetch response:", error);
      const errorMessage: Message = {
        sender: "bot",
        text: "Sorry, something went wrong. Please try again later.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: 0,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {/* Chat Messages */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            p: 2,
            bgcolor: "background.default",
          }}
        >
          <List>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                sx={{
                  textAlign: message.sender === "user" ? "right" : "left",
                  justifyContent:
                    message.sender === "user" ? "flex-end" : "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    p: 1,
                    borderRadius: 2,
                    bgcolor:
                      message.sender === "user" ? "primary.main" : "grey.300",
                    color: message.sender === "user" ? "white" : "black",
                    maxWidth: "75%",
                    wordWrap: "break-word",
                  }}
                >
                  {message.text}
                </Box>
              </ListItem>
            ))}
          </List>
          <List>
            {botResponse && (
              <ListItem
                sx={{
                  textAlign: "left",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    p: 1,
                    borderRadius: 2,
                    bgcolor: "grey.300",
                    color: "black",
                    maxWidth: "75%",
                    wordWrap: "break-word",
                  }}
                >
                  {botResponse.modelResponse.content.map((content, index) => (
                    <Typography key={index}>{content.text}</Typography>
                  ))}
                </Box>
              </ListItem>
            )}
          </List>
        </Box>

        {/* Input Area */}
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            position: "absolute",
            bottom: 50,
            left: 40,
            width: "95%",
            justifyContent: "center",
            p: 0,
          }}
        >
          <InputBase
            sx={{
              flex: 1,
              bgcolor: "grey.100",
              px: 1,
              borderRadius: 1,
            }}
            placeholder="Type your message..."
            value={input}
            onChange={handleInputChange}
            disabled={isLoading}
          />
          <IconButton
            color="primary"
            onClick={sendMessage}
            disabled={isLoading || !input.trim()}
          >
            {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default PricingChatBot;
