import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import InsuranceProviders from "../pages/InsuranceProviders";
import CareCenters from "../pages/CareCenters";
import MyCoverage from "../pages/MyCoverage";
import ShoppingCart from "../pages/ShoppingCart";
import Integrations from "../pages/Integrations";
import Settings from "../pages/Settings";
import ReportingPlanPage from "../pages/ReportingPlan";
import CodeDetails from "../pages/CodeDetails";
import CareProviders from "../pages/CareProviders";
import InsuranceProviderPage from "../pages/InsuranceProvider";

const DashboardRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/insurance_providers" element={<InsuranceProviders />} />
    <Route path="/care_providers" element={<CareProviders />} />
    <Route path="/care_centers" element={<CareCenters />} />
    <Route path="/my_coverage" element={<MyCoverage />} />
    <Route path="/shopping_cart" element={<ShoppingCart />} />
    {/* <Route path="/reports/claims" element={<Claims />} /> */}
    {/* <Route path="/reports/employees" element={<Employees />} /> */}
    {/* <Route path="/reports/dependants" element={<Dependants />} /> */}
    <Route path="/integrations" element={<Integrations />} />
    <Route path="/settings" element={<Settings />} />
    {/* DETAIL ROUTES */}
    <Route
      path="/insurance_provider/:uuid"
      element={<InsuranceProviderPage />}
    />
    <Route path="/reporting_plans/:id" element={<ReportingPlanPage />} />
    <Route path="/code/:id" element={<CodeDetails />} />
  </Routes>
);

export default DashboardRoutes;
