import {
  BillingCodeTypes,
  BillingClassTypes,
  NegotiatedTypes,
  NegotiationArrangements,
} from "./InNetworkEnums";

export interface Filters {
  billingCodeTypes: string[];
  billingClassTypes: string[];
  negotiatedTypes: string[];
  negotiationArrangements: string[];
}

export const defaultFilters: Filters = {
  billingCodeTypes: BillingCodeTypes,
  billingClassTypes: BillingClassTypes,
  negotiatedTypes: NegotiatedTypes,
  negotiationArrangements: NegotiationArrangements,
};
