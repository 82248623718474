export const BillingCodeTypes = [
  "CPT",
  "HCPCS",
  "ICD",
  "MS-DRG",
  "R-DRG",
  "S-DRG",
  "APS-DRG",
  "AP-DRG",
  "APR-DRG",
  "APC",
  "NDC",
  "HIPPS",
  "LOCAL",
  "EAPG",
  "CDT",
  "RC",
  "CSTM-ALL",
];
export const BillingClassTypes = ["professional", "institutional", "both"];
export const NegotiationArrangements = ["ffs", "bundle", "capitation"];
export const NegotiatedTypes = [
  "negotiated",
  "derived",
  "fee schedule",
  "percentage",
  "per diem",
];
