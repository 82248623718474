import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import {
  NegotiatedPrice,
  NegotiatedRate,
  OpenSearchInNetworkRecord,
  ProviderGroup,
} from "../../interfaces";
import { matchedPlaces } from "../../utils/PlacesOfService";
import { Link, Tooltip, Typography } from "@mui/material";
import { ProviderCell } from "./ProviderCell";

interface TableProps {
  selectedRecord: OpenSearchInNetworkRecord | null;
}

const ColumnsConfig: GridColDef[] = [
  { field: "id", headerName: "ID", width: 100, hideable: true },
  { field: "negotiated_type", headerName: "Negotiated Type", width: 100 },
  { field: "negotiated_rate", headerName: "Negotiated Rate", width: 100 },
  //   {
  //     field: "expiration_date",
  //     headerName: "Expiration Date",
  //     width: 200,
  //   },
  { field: "billing_class", headerName: "Billing Class", width: 200 },
  {
    field: "billing_code_modifier",
    headerName: "Billing Code Modifier",
  },
  {
    field: "tin",
    headerName: "Tin",
    width: 200,
    hideable: true,
    renderCell: (params) => (
      <>
        {params.value.value}
        <sup>({params.value.type})</sup>
      </>
    ),
  },
  {
    field: "provider",
    headerName: "Provider Details",
    width: 350,
    renderCell: (params) =>
      params.value[0] === 0 ? (
        "---"
      ) : (
        <ProviderCell provider={params.value[0]} />
      ),
  },
  {
    field: "service_code",
    headerName: "Service Code",
    renderCell: (params) =>
      params.value.length > 0 && (
        <>
          {matchedPlaces(params.value)
            .slice(0, 9)
            .map((place) => (
              <Tooltip key={place.code} title={place.name} arrow>
                <Typography
                  display="inline"
                  color="primary"
                  fontSize={12}
                  mr={0.5}
                  sx={{ cursor: "pointer" }}
                >
                  ({place.code})
                </Typography>
              </Tooltip>
            ))}
          {params.value.length > 5 && (
            <Tooltip title={"View More"} arrow>
              <Typography
                display="inline"
                color="primary"
                fontSize={12}
                mr={0.5}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // Add your "View More" logic here
                  alert("View more clicked!");
                }}
              >
                View More<sup>({params.value.length})</sup>
              </Typography>
            </Tooltip>
          )}
        </>
      ),
    width: 300,
  },
];

const NegotiatedPricesTable: React.FC<TableProps> = ({ selectedRecord }) => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const generateRows = (): GridRowsProp => {
    if (!selectedRecord?.negotiated_rates) {
      return [];
    }

    return selectedRecord.negotiated_rates.flatMap((rate: NegotiatedRate) =>
      rate.negotiated_prices.flatMap(
        (price: NegotiatedPrice) =>
          rate.provider_groups?.map((group: ProviderGroup) => ({
            id: uuidv4(), // Unique ID for each row
            negotiated_type: price.negotiated_type,
            negotiated_rate: price.negotiated_rate,
            expiration_date: price.expiration_date,
            billing_class: price.billing_class,
            service_code: price.service_code || [],
            billing_code_modifier: price.billing_code_modifier || [],
            provider: group.npi || [],
            tin: group.tin,
          })) || []
      )
    );
  };

  useEffect(() => {
    const updatedRows = generateRows();
    setRows(updatedRows);
  }, [selectedRecord]);

  return (
    <div style={{ height: "auto", width: "99%" }}>
      <DataGrid
        rows={rows}
        columns={ColumnsConfig}
        checkboxSelection={false}
        rowHeight={30}
        // getRowHeight={() => 'auto'} // Automatically adjust row height
        columnVisibilityModel={{
          id: false,
        }}
      />
    </div>
  );
};

export default NegotiatedPricesTable;
