import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { useDemoRouter } from "@toolpad/core/internal";
import { SearchProvider } from "../SearchContext";
import { useAuth } from "../AuthContext";
import { NAVIGATION } from "../components/MainNavLeftMenu";
import { AppTheme } from "../Theme";
import DashboardRoutes from "../routes/DashboardRoutes";
import SignInSide from "./sign-in-side/SignInSide";

interface DemoProps {
  window?: () => Window;
}

export function DashboardLayoutBasic(props: DemoProps) {
  const { user } = useAuth();

  const { window } = props;

  const router = useDemoRouter("");

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  // const { isAuthenticated } = useAuth(); // Use the authentication context

  if (!user) {
    return <SignInSide />;
    // return SignIn();
  }

  return (
    <SearchProvider>
      <AppProvider
        navigation={NAVIGATION}
        router={router}
        theme={AppTheme}
        window={demoWindow}
        branding={{
          logo: (
            <img src={`https://beta.conduit-health.com/logo.png`} alt="logo" />
          ),
          title: "",
        }}
      >
        <DashboardLayout
          defaultSidebarCollapsed
          // slots={{ toolbarActions: Search }}
          allowFullScreen
          loading={"lazy"}
        >
          {DashboardRoutes()}
        </DashboardLayout>
      </AppProvider>
    </SearchProvider>
  );
}

function App() {
  return <DashboardLayoutBasic />;
}

export default App;
