import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useEffect } from "react";
import { useAuth } from "../../AuthContext";
import { ApiClient } from "../../clients/ApiClient";
import { CircularProgress } from "@mui/material";

interface TopCodeAggregation {
  average_rate: number;
  doc_count: number;
  code: string;
}
export const TopCodesBar: React.FC = () => {
  const { token } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [aggregation, setAggregation] = React.useState<
    TopCodeAggregation[] | null
  >(null);
  useEffect(() => {
    setLoading(true);
    const fetchAggregation = async () => {
      if (!token) {
        return;
      }
      try {
        const apiClient = new ApiClient(token);
        const apiUrl = `${window.location.origin}/api/top_billing_codes`;
        const data = await apiClient.request<TopCodeAggregation[]>(apiUrl);
        setAggregation(data);
      } catch (error) {
        setLoading(false);
        // DISPLAY ERROR TOAST
      }
      setLoading(false);
    };
    fetchAggregation();
  }, [token]);

  return (
    <>
      {loading && <CircularProgress size={"small"} />}
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: aggregation ? aggregation.map((item) => item.code) : [],
          },
        ]}
        series={[
          {
            data: aggregation
              ? aggregation.map((item) => item.average_rate)
              : [],
          },
        ]}
        width={700}
        height={300}
      />
    </>
  );
};
