import { Container } from "@mui/material";
import React from "react";
import { HomeHeaderSVG } from "../../components/headers/HomeGeaderSVG";

const MyChartPage: React.FC = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <HomeHeaderSVG />
      {/* SEARCH INPUT MOBILE*/}
      {/* FOOTER NAV */}
    </Container>
  );
};

export default MyChartPage;
