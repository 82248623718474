import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import {
  Authenticator,
  ThemeProvider,
  useTheme,
  View,
  Theme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import { InitialProvider } from "./InitialContext";
import { Button, colors, Typography } from "@mui/material";
import { AuthProvider } from "./AuthContext";

Amplify.configure(awsconfig);

const BrandingColors = {
  primary: "#1976d2",
};
function App() {
  const authComponents = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <img
            style={{ maxHeight: "120px" }}
            alt="Conduit Health"
            src={`${window.location.origin}/logo.png`}
          />
        </View>
      );
    },
    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Typography fontSize={12}>&copy; All Rights Reserved</Typography>
        </View>
      );
    },
  };

  const { tokens } = useTheme();
  const theme: Theme = {
    name: "Auth Theme",
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay["10"]}`,
            borderWidth: "0",
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: BrandingColors.primary,
          },
          _hover: {
            backgroundColor: BrandingColors.primary,
          },
          _focus: {
            backgroundColor: BrandingColors.primary,
          },
          link: {
            color: BrandingColors.primary,
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 1px ${colors.blue[400]}`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.neutral["80"],
            _active: {
              borderColor: colors.blue[400],
              color: colors.blue[400],
            },
          },
        },
      },
    },
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<UnauthenticatedRoutes />} />
        <Route
          path="/*"
          element={
            <ThemeProvider theme={theme}>
              <Authenticator hideSignUp={false} components={authComponents}>
                {({ signOut, user }) => (
                  <AuthProvider user={user}>
                    <InitialProvider>
                      <AuthenticatedRoutes />
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          position: "fixed",
                          right: 70,
                          top: 17,
                          zIndex: 10000,
                        }}
                        onClick={signOut as () => void}
                      >
                        Sign Out
                      </Button>
                    </InitialProvider>
                  </AuthProvider>
                )}
              </Authenticator>
            </ThemeProvider>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
