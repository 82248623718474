import { SearchOutlined } from "@mui/icons-material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import BasicScatter from "../charts/BasicScatter";
import BasicBar from "../charts/BasicBar";

export const DashboardBigCharts: React.FC = () => {
  return (
    <Grid pt={2} container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card style={{ minHeight: "375px" }}>
          <CardContent>
            <Typography
              gutterBottom
              sx={{ color: "text.secondary", fontSize: 14 }}
            >
              Insurance Carrier Pricing Comparison
            </Typography>
            <Typography variant="h5" component="div">
              25
            </Typography>
            <Typography fontSize={10} sx={{ color: "text.secondary", mb: 1.5 }}>
              last 30 days
            </Typography>
            <BasicScatter />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{ minHeight: "375px" }}>
          <CardContent>
            <Typography
              gutterBottom
              sx={{ color: "text.secondary", fontSize: 14 }}
            >
              Top 100 Medical Code Metrics
            </Typography>
            <Typography variant="h5" component="div">
              125,457
            </Typography>
            <Typography fontSize={10} sx={{ color: "text.secondary", mb: 1.5 }}>
              last 30 days
            </Typography>
            <BasicBar />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
