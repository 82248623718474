import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import { HealthInsuranceProvider } from "../../interfaces/Models";
import { FileList } from "../files/FileList";
import HealthInsuranceProviderPricingTable from "../tables/HealthInsuranceProviderPricingTable";
import ProviderReportingPlansTable from "../tables/ProviderReportingPlansTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ComponentProps {
  healthInsuranceProvider: HealthInsuranceProvider | null;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabs = [
  "Reporting Plans",
  "Pricing",
  "Care Centers",
  "Providers",
  "In Network MRF Files",
  "Processing Jobs",
  // "Metadata",
];

export default function HealthInsuranceProviderTabPanel({
  healthInsuranceProvider,
}: ComponentProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Reporting Plan Tabs"
          >
            {tabs.map((tab, index) => (
              <Tab label={tab} {...a11yProps(index)} />
            ))}
            {/* <Tab label="Pricing" {...a11yProps(0)} />
            <Tab label="Care Centers" {...a11yProps(1)} />
            <Tab label="Providers" {...a11yProps(2)} />
            <Tab label="In Network MRF Files" {...a11yProps(3)} />
            <Tab label="Processing Jobs" {...a11yProps(4)} />
            <Tab label="Metadata" {...a11yProps(5)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {/* <HealthInsuranceProviderPricingTable /> */}
          <ProviderReportingPlansTable providerId={7} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HealthInsuranceProviderPricingTable />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <h2>Care Centers</h2>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <h2>Providers</h2>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <FileList files={healthInsuranceProvider?.files || []} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <h2>Processing Jobs</h2>
        </TabPanel>
        {/* <TabPanel value={value} index={6}>
          <pre style={{ fontSize: "12px" }}>
            {JSON.stringify(healthInsuranceProvider, null, 2)}
          </pre>
        </TabPanel> */}
      </Box>
    </Container>
  );
}
