import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionIcon from "@mui/icons-material/Description";
import LayersIcon from "@mui/icons-material/Layers";
import { Navigation } from "@toolpad/core/AppProvider";
import {
  CreditCard,
  HeatPumpSharp,
  LocalHospitalTwoTone,
  MedicalServices,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import OverlayLink from "../pages/OverlayLink";
import NavIconLink from "./NavIconLink";

export const NAVIGATION: Navigation = [
  {
    kind: "header",
    title: "Main items",
  },
  {
    kind: "page",
    segment: "dashboard",
    title: "Dashboard",
    icon: <NavIconLink to="/dashboard" icon={<DashboardIcon />} />,
    pattern: "/dashboard",
    action: (
      <>
        <OverlayLink to="/dashboard" />
      </>
    ),
  },
  {
    kind: "page",
    segment: "insurance_providers",
    title: "Insurance Providers",
    icon: (
      <NavIconLink to="/dashboard/insurance_providers" icon={<CreditCard />} />
    ),
    pattern: "/dashboard/insurance_providers",
    action: <OverlayLink to="/dashboard/insurance_providers" />,
  },
  {
    kind: "page",
    segment: "care_providers",
    title: "Care Providers",
    icon: (
      <NavIconLink to="/dashboard/care_providers" icon={<MedicalServices />} />
    ),
    pattern: "/dashboard/care_providers",
    action: <OverlayLink to="/dashboard/care_providers" />,
  },
  {
    kind: "page",
    segment: "care_centers",
    title: "Care Centers",
    icon: (
      <NavIconLink
        to="/dashboard/care_centers"
        icon={<LocalHospitalTwoTone />}
      />
    ),
    pattern: "/dashboard/care_centers",
    action: <OverlayLink to="/dashboard/care_centers" />,
  },
  // {
  //   kind: "page",
  //   segment: "my_coverage",
  //   title: "My Coverage",
  //   icon: <NavIconLink to="/dashboard/my_coverage" icon={<HeatPumpSharp />} />,
  //   pattern: "/dashboard/my_coverage",
  //   action: <OverlayLink to="/dashboard/my_coverage" />,
  // },
  // {
  //   kind: "page",
  //   segment: "shopping_cart",
  //   title: "Shopping Cart",
  //   icon: (
  //     <NavIconLink to="/dashboard/shopping_cart" icon={<ShoppingCartIcon />} />
  //   ),
  //   pattern: "/dashboard/shopping_cart",
  //   action: <OverlayLink to="/dashboard/shopping_cart" />,
  // },
  {
    kind: "divider",
  },
  // {
  //   kind: "header",
  //   title: "Analytics",
  // },
  // {
  //   kind: "page",
  //   segment: "reports",
  //   title: "Reports",
  //   icon: <NavIconLink to="/dashboard/reports" icon={<BarChartIcon />} />,
  //   children: [
  //     {
  //       kind: "page",
  //       segment: "claims",
  //       title: "Claims",
  //       icon: <NavIconLink to="/dashboard/claims" icon={<DescriptionIcon />} />,
  //       pattern: "/dashboard/reports/claims",
  //       action: <OverlayLink to="/dashboard/reports/claims" />,
  //     },
  //     {
  //       kind: "page",
  //       segment: "employees",
  //       title: "Employees",
  //       icon: (
  //         <NavIconLink to="/dashboard/employees" icon={<DescriptionIcon />} />
  //       ),
  //       pattern: "/dashboard/reports/employees",
  //       action: <OverlayLink to="/dashboard/reports/employees" />,
  //     },
  //     {
  //       kind: "page",
  //       segment: "dependants",
  //       title: "Dependants",
  //       icon: (
  //         <NavIconLink to="/dashboard/dependants" icon={<DescriptionIcon />} />
  //       ),
  //       pattern: "/dashboard/reports/dependants",
  //       action: <OverlayLink to="/dashboard/reports/dependants" />,
  //     },
  //   ],
  // },
  {
    kind: "page",
    segment: "integrations",
    title: "Integrations",
    icon: <NavIconLink to="/dashboard/integrations" icon={<LayersIcon />} />,
    pattern: "/dashboard/integrations",
    action: <OverlayLink to="/dashboard/integrations" />,
  },
  {
    kind: "page",
    segment: "settings",
    title: "Settings",
    icon: <NavIconLink to="/dashboard/settings" icon={<SettingsIcon />} />,
    pattern: "/dashboard/settings",
    action: <OverlayLink to="/dashboard/settings" />,
  },
];
