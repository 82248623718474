import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Avatar,
  Button,
  ButtonGroup,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReportingPlan } from "../interfaces/Models";
import { formatBytes, getFileNameFromUrl } from "../utils";
import { FileDownload } from "@mui/icons-material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ComponentProps {
  reporting_plan: ReportingPlan | null;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportingPlanTabPanel({
  reporting_plan,
}: ComponentProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Reporting Plan Tabs"
          >
            <Tab label="Pricing" {...a11yProps(0)} />
            <Tab label="Care Centers" {...a11yProps(1)} />
            <Tab label="Providers" {...a11yProps(2)} />
            <Tab label="In Network MRF Files" {...a11yProps(3)} />
            <Tab label="Processing Jobs" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <h2>Pricing</h2>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <h2>Care Centers</h2>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <h2>Providers</h2>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Typography variant="h6" gutterBottom>
            {reporting_plan &&
              reporting_plan.provider_files &&
              reporting_plan.provider_files.length}{" "}
            In Network MRF Files Found
          </Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {reporting_plan &&
              reporting_plan.provider_files &&
              reporting_plan.provider_files.sort().map((file) => (
                <>
                  <ListItem key={file.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <FileDownload />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={getFileNameFromUrl(file.url)}
                      secondary={
                        <>
                          Status: {file.status}
                          <br />
                          Size: {formatBytes(file.size ? file.size : 0)}
                        </>
                      }
                    />
                    <ButtonGroup>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        href={file.url}
                        target="_blank"
                      >
                        Process
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        href={file.url}
                        target="_blank"
                      >
                        Details
                      </Button>
                    </ButtonGroup>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              ))}
          </List>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <h2>Processing Jobs</h2>
        </TabPanel>
      </Box>
    </Container>
  );
}
