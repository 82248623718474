import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Divider,
  TextField,
  Grid,
} from "@mui/material";
import { HealthInsuranceProvider } from "../interfaces/Models";
import { useAuth } from "../AuthContext";
import HealthInsuranceProviderTabPanel from "../components/TabPanels/HealthInsuranceProviderTabPanel";
import { BasicSearchFilters } from "../components/drawers/BasicSearchFilters";

const InsuranceProviderPage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { token } = useAuth();
  const [insuranceProvider, setInsuranceProvider] =
    useState<HealthInsuranceProvider | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      return;
    }
    fetch(`${window.location.origin}/api/insurance_provider/${uuid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setInsuranceProvider(data);
        setLoading(false);
      });
  }, [uuid, token]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!insuranceProvider) {
    return (
      <>
        {loading && (
          <CircularProgress
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Typography variant="h6">Not Found</Typography>
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6" mb={3}>
            {insuranceProvider.name}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <TextField
            id=""
            label="search..."
            variant="outlined"
            size="small"
            style={{ float: "right", width: "400px" }}
            onChange={(e) => console.log(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && console.log()}
          />
        </Grid>
        <Grid item xs={1}>
          <BasicSearchFilters />
        </Grid>
      </Grid>

      <Divider sx={{ mb: 2 }} />

      {insuranceProvider && (
        <HealthInsuranceProviderTabPanel
          healthInsuranceProvider={insuranceProvider}
        />
      )}
    </Box>
  );
};

export default InsuranceProviderPage;
