import React, { useEffect, useState, useCallback } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  Grid,
  Typography,
  IconButton,
  CardContent,
  CardActions,
  Avatar,
  Collapse,
  styled,
  CardMedia,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { blueGrey, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButtonProps } from "@mui/material/IconButton";

import {
  OpenSearchInNetworkRecord,
  OpenSearchInNetworkResponse,
} from "../../interfaces";
import { useSearch } from "../../SearchContext";
import debounce from "lodash.debounce";
import { MedicalInformationSharp } from "@mui/icons-material";
import { tokenToString } from "typescript";
import { useAuth } from "../../AuthContext";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const PatientEventResults: React.FC = () => {
  const { token } = useAuth();
  const { searchQuery, searchHistory } = useSearch();
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState<OpenSearchInNetworkRecord[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const fetchRecords = useCallback(
    debounce((page: number, pageSize: number, keyword: string) => {
      setLoading(true);
      fetch(
        `${window.location.origin}/api/search/in-network-v1/keyword_search?keyword=${keyword}&page=${page + 1}&size=${pageSize}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data: OpenSearchInNetworkResponse) => {
          setRecords(data.results);
          setLoading(false);
        });
    }, 300),
    []
  );

  useEffect(() => {
    fetchRecords(paginationModel.page, paginationModel.pageSize, searchQuery);
  }, [paginationModel, searchHistory, fetchRecords]);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid pt={2} container spacing={2} xs={12} sm={12} md={12}>
      {records &&
        records.map((row: OpenSearchInNetworkRecord) => (
          <Grid item xs={12} md={6}>
            <Card sx={{ maxWidth: "100%" }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: blueGrey[500] }} aria-label="recipe">
                    <Typography>
                      <MedicalInformationSharp />
                    </Typography>
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                title={`Claim Event`}
                subheader={`${row.billing_code}`}
              />
              <CardMedia
                component="img"
                height="194"
                image="https://placehold.co/394x194/eeeeee/eeeeee"
                alt=""
              />
              <CardContent>
                <Typography
                  variant="body2"
                  fontSize={12}
                  sx={{ color: "text.secondary" }}
                >
                  {row.description}
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default PatientEventResults;
