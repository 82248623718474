import { Box, Chip, Stack, Typography } from "@mui/material";
import { NegotiatedRate } from "../../interfaces";
import { green } from "@mui/material/colors";
import { Info, InfoRounded } from "@mui/icons-material";

const greenShades = [
  green[900],
  green[800],
  green[700],
  green[600],
  green[500],
  green[400],
  green[300],
  green[200],
  green[100],
];

// Helper functions for mean, median, and mode
const calculateMean = (rates: number[]) => {
  const sum = rates.reduce((a, b) => a + b, 0);
  return (sum / rates.length).toFixed(2);
};

// Custom cell renderer for negotiated rates
export const TechnicalRatesAggregation: React.FC<{
  value: NegotiatedRate[];
}> = ({ value }) => {
  const rates = value
    .flatMap((rateObj) => rateObj.negotiated_prices)
    .filter(
      (price) =>
        price.billing_code_modifier &&
        price.billing_code_modifier.length > 0 &&
        price.billing_code_modifier[0] === "TC" &&
        price
    )
    .map((price) => price.negotiated_rate);

  if (!rates.length)
    return (
      <Stack direction="column" alignItems="left" spacing={1}>
        <Typography fontSize={18} fontWeight={700}>
          Technical Rates
          {/* <InfoRounded fontSize="small" /> */}
        </Typography>
        <Box display="flex" alignItems="center" height="100%">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Chip label={`High: $0.00`} color="default" />
            <Chip label={`Avg: $0.00`} color="default" />
            <Chip label={`Low: $0.00`} color="default" />
          </Stack>
        </Box>
      </Stack>
    );

  const mean = calculateMean(rates);
  // const median = calculateMedian(rates); // Available for later use
  // const mode = calculateMode(rates); // Available for later use
  const lowest = Math.min(...rates).toFixed(2);
  const highest = Math.max(...rates).toFixed(2);

  const numPrices = value.reduce(
    (total, rateObj) => total + rateObj.negotiated_prices.length,
    0
  );

  return (
    <Stack direction="column" alignItems="left" spacing={1}>
      <Typography fontSize={18} fontWeight={700}>
        Technical Rates
        {/* <InfoRounded fontSize="small" /> */}
      </Typography>
      <Box display="flex" alignItems="center" height="100%">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Chip
            label={`High: $${highest}`}
            sx={{
              backgroundColor: greenShades[0],
              color: "white",
            }}
          />
          <Chip
            label={`Avg: $${mean}`}
            sx={{
              backgroundColor: greenShades[2],
              color: "white",
            }}
          />
          <Chip
            label={`Low: $${lowest}`}
            sx={{
              backgroundColor: greenShades[6],
              color: "white",
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};
