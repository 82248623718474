import React, { useEffect, useState } from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Box, Card } from "@mui/material";
import { Download, OpenInNew } from "@mui/icons-material";
import NavIconLink from "../NavIconLink";
import { useAuth } from "../../AuthContext";

interface HealthInsuranceProvider {
  id: number;
  uuid: string;
  name: string;
}

const ColumnsConfig: GridColDef[] = [
  {
    field: "uuid",
    headerName: "id",
    width: 75,
    maxWidth: 75,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "100%",
        }}
      >
        <NavIconLink
          to={`/dashboard/insurance_provider/${params.value}`}
          icon={<Download fontSize="small" />}
        />
        <NavIconLink
          to={`/dashboard/insurance_provider/${params.value}`}
          icon={<OpenInNew fontSize="small" />}
        />
      </Box>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    width: 300,
    maxWidth: 300,
  },
];

const HealthInsuranceProviderTable: React.FC = () => {
  const { token } = useAuth();
  const [providers, setProviders] = useState<HealthInsuranceProvider[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!token) {
      setRows([]);
      setLoading(false);
      return;
    }
    fetch(`api/providers?page=${1}&per_page=${10}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProviders(data);
        setColumns(ColumnsConfig);

        // Set rows based on the response data
        const rows = data.map((item: HealthInsuranceProvider) => ({
          id: item.id,
          ...Object.fromEntries(
            Object.entries(item).filter(([key]) => key !== "id")
          ),
        }));
        setRows(rows);
        setLoading(false);
      });
  }, [token]);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={30}
        loading={loading}
      />
    </div>
  );
};

export default HealthInsuranceProviderTable;
