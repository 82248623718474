import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Navigation } from "@toolpad/core/AppProvider";
import OverlayLink from "../pages/OverlayLink";
import NavIconLink from "./NavIconLink";
import { LocalHospitalTwoTone } from "@mui/icons-material";
import { GridSearchIcon } from "@mui/x-data-grid";

const base_route = "/shopper";

export const ShopperLeftNav: Navigation = [
  {
    kind: "header",
    title: "Main items",
  },
  {
    kind: "page",
    segment: "home",
    title: "Home",
    icon: <NavIconLink to="/shopper" icon={<DashboardIcon />} />,
    pattern: "/shopper",
    action: (
      <>
        <OverlayLink to="/shopper" />
      </>
    ),
  },
  {
    kind: "page",
    segment: "shop",
    title: "Shop",
    icon: <NavIconLink to="/shopper/shop" icon={<GridSearchIcon />} />,
    pattern: "/shopper/shop",
    action: <OverlayLink to="/shopper/shop" />,
  },
  {
    kind: "page",
    segment: "my_chart",
    title: "My Chart",
    icon: (
      <NavIconLink to="/shopper/my_chart" icon={<LocalHospitalTwoTone />} />
    ),
    pattern: "/shopper/my_chart",
    action: <OverlayLink to="/shopper/my_chart" />,
  },
  {
    kind: "page",
    segment: "cart",
    title: "Shopping Cart",
    icon: <NavIconLink to="/shopper/cart" icon={<ShoppingCartIcon />} />,
    pattern: "/shopper/cart",
    action: <OverlayLink to="/shopper/cart" />,
  },
];
