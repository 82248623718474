import React from "react";
import { ZipCode } from "../../interfaces/ZipCode";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";
// import { mapTheme } from "./mapTheme";

interface GoogleMapComponentProps {
  apiKey: string;
  initialPosition: { lat: number; lng: number };
  zoom: number;
  selectedZipCodes?: ZipCode[];
}

export const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({
  apiKey,
  initialPosition,
  zoom,
  selectedZipCodes,
}) => {
  return (
    <APIProvider apiKey={apiKey}>
      <Map
        mapId="CONDUIT_DEV"
        style={{ width: "100%", height: 300 }}
        center={selectedZipCodes ? selectedZipCodes[0] : initialPosition}
        defaultCenter={initialPosition}
        zoom={zoom}
        defaultZoom={zoom}
        disableDefaultUI={true}
        // styles={mapTheme}
      >
        {selectedZipCodes &&
          selectedZipCodes.map((zipCode) => (
            <AdvancedMarker
              key={zipCode.zip}
              position={{ lat: zipCode.lat, lng: zipCode.lng }}
            >
              <Pin
                background={"#FBBC04"}
                glyphColor={"#000"}
                borderColor={"#000"}
              />
            </AdvancedMarker>
          ))}
      </Map>
    </APIProvider>
  );
};
