import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { ZipCode } from "../interfaces/ZipCode";
import { useSearch } from "../SearchContext";

function LocationSelector() {
  const [loading, setLoading] = useState(false);
  //   const [options, setOptions] = useState<ZipCode[]>([]);
  const [zipCodes, setZipCodes] = useState<ZipCode[]>([]);
  const [previousSelection, setPreviousSelection] = useState<ZipCode[]>([]);
  //   const [selectedLocations, setSelectedLocations] = useState<ZipCode[]>([]);
  const { searchQuery, addSelectedZipCodes } = useSearch();

  useEffect(() => {
    // Fetch initial location data
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${window.location.origin}/api/zip_codes`);
        const data = await response.json();
        setZipCodes(data.zip_codes);
        addSelectedZipCodes([data.zip_codes[0]]);
      } catch (error) {
        console.error("Error fetching locations:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLocations();
  }, [searchQuery]);

  const handleSelectionChange = (
    event: React.SyntheticEvent,
    newValue: ZipCode[]
  ) => {
    // const addedItems = newValue.filter(
    //   (item) => !previousSelection.some((prevItem) => prevItem.zip === item.zip)
    // );
    // const removedItems = previousSelection.filter(
    //   (item) => !newValue.some((newItem) => newItem.zip === item.zip)
    // );

    setPreviousSelection(newValue);
    addSelectedZipCodes(newValue);
  };
  return (
    <Autocomplete
      style={{ width: 300 }}
      multiple
      options={zipCodes}
      getOptionLabel={(option) =>
        `${option.city}, ${option.state_id} - ${option.zip}`
      }
      filterSelectedOptions
      onChange={handleSelectionChange}
      isOptionEqualToValue={(option, value) => option.zip === value.zip}
      loading={loading}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select Locations"
          placeholder=""
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default LocationSelector;
