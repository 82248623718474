import React, { useEffect, useState } from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

interface HealthInsuranceProvider {
  id: number;
  uuid: string;
  name: string;
}

const MrfFilesTable: React.FC = () => {
  const [providers, setProviders] = useState<HealthInsuranceProvider[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    fetch(
      `${window.location.origin}/api/provider_files?page=${0}&per_page=${100}`
    )
      .then((response) => response.json())
      .then((data) => {
        setProviders(data);

        // Set columns based on the keys of the first object in the response
        if (data.length > 0) {
          const cols = Object.keys(data[0]).map((key) => ({
            field: key,
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
            width: 150,
          }));
          setColumns(cols);
        }

        // Set rows based on the response data
        const rows = data.map((item: HealthInsuranceProvider) => ({
          id: item.id,
          ...Object.fromEntries(
            Object.entries(item).filter(([key]) => key !== "id")
          ),
        }));
        setRows(rows);
      });
  }, []);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} rowHeight={30} />
    </div>
  );
};

export default MrfFilesTable;
