import DashboardIcon from "@mui/icons-material/Dashboard";
import LayersIcon from "@mui/icons-material/Layers";
import { Navigation } from "@toolpad/core/AppProvider";
import {
  CreditCard,
  LocalHospitalTwoTone,
  MedicalServices,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import OverlayLink from "../pages/OverlayLink";
import NavIconLink from "./NavIconLink";

export const NAVIGATION: Navigation = [
  {
    kind: "header",
    title: "Main items",
  },
  {
    kind: "page",
    segment: "dashboard",
    title: "Dashboard",
    icon: <NavIconLink to="/dashboard" icon={<DashboardIcon />} />,
    pattern: "/dashboard",
    action: (
      <>
        <OverlayLink to="/dashboard" />
      </>
    ),
  },
  {
    kind: "page",
    segment: "insurance_providers",
    title: "Insurance Providers",
    icon: (
      <NavIconLink to="/dashboard/insurance_providers" icon={<CreditCard />} />
    ),
    pattern: "/dashboard/insurance_providers",
    action: <OverlayLink to="/dashboard/insurance_providers" />,
  },
  {
    kind: "page",
    segment: "care_providers",
    title: "Care Providers",
    icon: (
      <NavIconLink to="/dashboard/care_providers" icon={<MedicalServices />} />
    ),
    pattern: "/dashboard/care_providers",
    action: <OverlayLink to="/dashboard/care_providers" />,
  },
  {
    kind: "page",
    segment: "care_centers",
    title: "Care Centers",
    icon: (
      <NavIconLink
        to="/dashboard/care_centers"
        icon={<LocalHospitalTwoTone />}
      />
    ),
    pattern: "/dashboard/care_centers",
    action: <OverlayLink to="/dashboard/care_centers" />,
  },
  {
    kind: "divider",
  },

  {
    kind: "page",
    segment: "integrations",
    title: "Integrations",
    icon: <NavIconLink to="/dashboard/integrations" icon={<LayersIcon />} />,
    pattern: "/dashboard/integrations",
    action: <OverlayLink to="/dashboard/integrations" />,
  },
  {
    kind: "page",
    segment: "settings",
    title: "Settings",
    icon: <NavIconLink to="/dashboard/settings" icon={<SettingsIcon />} />,
    pattern: "/dashboard/settings",
    action: <OverlayLink to="/dashboard/settings" />,
  },
];
