import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

interface NavIconLinkProps {
  to: string;
  icon: React.ReactElement;
}

const NavIconLink: React.FC<NavIconLinkProps> = ({ to, icon }) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        navigate(to);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        color: "inherit",
        cursor: "pointer", // Add cursor pointer to indicate clickable area
      }}
    >
      {icon}
    </Box>
  );
};

export default NavIconLink;
