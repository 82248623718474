import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardApp from "./miniapps/DashboardApp";
import ShopperApp from "./miniapps/ShopperApp";
import VeracityApp from "./miniapps/VeracityApp";
import RequireAuth from "./RequireAuth";
import SignInSide from "./miniapps/sign-in-side/SignInSide";
import MarketingPage from "./miniapps/marketing-page/MarketingPage";
import { SignIn } from "./pages/auth/signin";

const AuthenticatedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/dashboard/*"
        element={
          <RequireAuth>
            <DashboardApp />
          </RequireAuth>
        }
      />
      <Route
        path="/shopper/*"
        element={
          <RequireAuth>
            <ShopperApp />
          </RequireAuth>
        }
      />
      <Route
        path="/veracity/*"
        element={
          <RequireAuth>
            <VeracityApp />
          </RequireAuth>
        }
      />
      {/* Redirect to SignIn if no match */}
      <Route path="/" element={<MarketingPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
