import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import VeracityInNetworkTable from "../../components/tables/VeracityInNetworkTable";
import { Close, CloseSharp, FilterListOutlined } from "@mui/icons-material";
import { useInitial } from "../../InitialContext";
import { BasicSearch } from "../../components/BasicSearch";
import {
  BillingClassTypes,
  BillingCodeTypes,
  NegotiatedTypes,
  NegotiationArrangements,
} from "../../utils/InNetworkEnums";
import { defaultFilters } from "../../utils/SearchFilters";
import { useSearch } from "../../SearchContext";
import ChatBot from "../../components/chat/ChatBot";

const VeracityHome: React.FC = () => {
  const { initialData } = useInitial();
  const { filters, setFilters } = useSearch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  return (
    <Container maxWidth={false} disableGutters>
      <Box pl={2} pt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Breadcrumbs>
              <Typography>Dashboard</Typography>
              <Typography>Veracity</Typography>
            </Breadcrumbs>
            <Typography fontSize={16} fontWeight={700} mt={2}>
              Overview
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              ml: "auto",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "30px",
            }}
          >
            <Tooltip title="Filters">
              <IconButton onClick={toggleDrawer(true)}>
                <FilterListOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Box
        ml={2}
        mt={4}
        sx={{ width: "100%", maxWidth: "97vw", overflowX: "hidden" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {BasicSearch()}
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              ml: "auto",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <ButtonGroup>
              <Button variant="outlined">Download Results</Button>
              <Button variant="outlined" onClick={() => setOpen(true)}>
                Excel Integration
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <VeracityInNetworkTable />
      </Box>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Excel Integration</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseSharp />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Paste the following VBA code into your Excel workbook to fetch &
            search data. Contact your rep for API key credentials.
          </Typography>
          <Typography gutterBottom>
            <pre style={{ width: "90%", display: "block", fontSize: 12 }}>
              {`
Sub FetchAPIData() 
  Dim Http As Object 
  Set Http = CreateObject("MSXML2.XMLHTTP.6.0") 

  Dim url As String 
  url = "https://yourapi.com/api/search/in-network-v1/keyword_search?keyword=" & Range("A1").Value 

  Http.Open "GET", url, False 
  Http.Send 

  Dim response As String 
  response = Http.responseText 

  ' Parse and write response data to cells, e.g., starting at B2 
  Range("B2").Value = response ' Simplified for example
End Sub`}
            </pre>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Learn More
          </Button>
          <Button autoFocus onClick={handleClose}>
            Download
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/* SEARCH FILTER MOVE  */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 800,
            padding: 2,
            marginTop: "65px",
          }}
          role="presentation"
        >
          <IconButton onClick={toggleDrawer(false)} style={{ float: "right" }}>
            <Close />
          </IconButton>
          <Typography variant="h5" component="h2" mb={4} gutterBottom>
            Search Filters
          </Typography>
          <Grid pt={2} container spacing={2}>
            <Grid item xs={12} md={10}>
              <Autocomplete
                size="small"
                value={filters.negotiationArrangements ?? []}
                options={NegotiationArrangements}
                getOptionLabel={(option) => option}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Negotiation Arrangement" />
                )}
                onInputChange={(event, value) => console.log()}
                onKeyDown={(event) => event.key === "Enter" && console.log()}
                onChange={(event, value) =>
                  setFilters({
                    ...filters,
                    negotiationArrangements: value ? value : [],
                  })
                }
                multiple
              />
            </Grid>
            <Grid item xs={12} md={12}></Grid>

            <Grid item xs={12} md={10}>
              <Autocomplete
                size="small"
                value={filters.negotiatedTypes ?? []}
                options={NegotiatedTypes}
                getOptionLabel={(option) => option}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Negotiated Types" />
                )}
                onInputChange={(event, value) => console.log()}
                onKeyDown={(event) => event.key === "Enter" && console.log()}
                onChange={(event, value) =>
                  setFilters({
                    ...filters,
                    negotiatedTypes: value ? value : [],
                  })
                }
                multiple
              />
            </Grid>
            <Grid item xs={12} md={12}></Grid>

            <Grid item xs={12} md={10}>
              <Autocomplete
                size="small"
                value={filters.billingCodeTypes ?? []}
                options={BillingCodeTypes}
                getOptionLabel={(option) => option}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Code Types" />
                )}
                onInputChange={(event, value) => console.log()}
                onKeyDown={(event) => event.key === "Enter" && console.log()}
                onChange={(event, value) =>
                  setFilters({
                    ...filters,
                    billingCodeTypes: value ? value : [],
                  })
                }
                multiple
              />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={10}>
              <Autocomplete
                size="small"
                value={filters.billingClassTypes ?? []}
                options={BillingClassTypes}
                getOptionLabel={(option) => option}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Billing Class" />
                )}
                onInputChange={(event, value) => console.log()}
                onKeyDown={(event) => event.key === "Enter" && console.log()}
                onChange={(event, value) =>
                  setFilters({
                    ...filters,
                    billingClassTypes: value ? value : [],
                  })
                }
                multiple
              />
            </Grid>
          </Grid>

          <ButtonGroup
            style={{ width: "90%", position: "absolute", bottom: 10 }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setFilters(defaultFilters)}
            >
              Reset
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setFilters(filters)}
            >
              Apply
            </Button>
          </ButtonGroup>

          <Button
            size="small"
            style={{ position: "absolute", bottom: 10, right: 30 }}
            variant="outlined"
            color="success"
            onClick={() => setFilters(filters)}
          >
            Save Default
          </Button>
        </Box>
      </Drawer>
      {/* END SEARCH FILTER MOVE */}
    </Container>
  );
};

export default VeracityHome;
