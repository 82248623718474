import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import HealthInsuranceProviderTable from "./tables/HealthInsuranceProviderTable";
import ReportingPlanTable from "./tables/ReportingPlanTable";
import InNetworkTable from "./tables/InNetworkTable";
import { Container } from "@mui/material";
import HealthProviderTable from "./tables/HealthProviderTable";
import AirflowTasksTable from "./tables/AirflowTasksTable";
import MrfFilesTable from "./tables/MrfFilesTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Pricing" {...a11yProps(0)} />
            <Tab label="Insurance Carriers" {...a11yProps(1)} />
            <Tab label="Reporting Plans" {...a11yProps(2)} />
            <Tab label="Care Providers" {...a11yProps(3)} />
            <Tab label="Processing Jobs" {...a11yProps(4)} />
            <Tab label="MRF Files" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ width: "100%", maxWidth: "92vw", overflowX: "hidden" }}>
            <InNetworkTable />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ width: "100%", maxWidth: "92vw", overflowX: "hidden" }}>
            <HealthInsuranceProviderTable />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box sx={{ width: "100%", maxWidth: "92vw", overflowX: "hidden" }}>
            <ReportingPlanTable />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box sx={{ width: "100%", maxWidth: "92vw", overflowX: "hidden" }}>
            <HealthProviderTable />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box sx={{ width: "100%", maxWidth: "92vw", overflowX: "hidden" }}>
            <AirflowTasksTable />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box sx={{ width: "100%", maxWidth: "92vw", overflowX: "hidden" }}>
            <MrfFilesTable />
          </Box>
        </TabPanel>
      </Box>
    </Container>
  );
}
