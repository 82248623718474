import React, { useState, useEffect } from "react";
import { Link, CircularProgress, Typography, Tooltip } from "@mui/material";
import { NpiOrganization, NpiProvider } from "../../interfaces/NpiProvider";
import { useAuth } from "../../AuthContext";

export const ProviderCell: React.FC<{ provider: string }> = ({ provider }) => {
  const { token } = useAuth();
  const [supplementalData, setSupplementalData] = useState<
    NpiProvider | NpiOrganization | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (provider[0] === (0 || "0")) {
      setLoading(false);
      return;
    }
    const fetchSupplementalData = async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/api/npi/${provider}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setSupplementalData(data);
      } catch (error) {
        console.error("Error fetching supplemental data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSupplementalData();
  }, [provider]);

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <>
      {supplementalData && (
        <Tooltip title={supplementalData && supplementalData.number}>
          <Typography variant="caption">
            {supplementalData &&
            (supplementalData as NpiProvider).basic.first_name &&
            (supplementalData as NpiProvider).basic.last_name
              ? `${(supplementalData as NpiProvider).basic.first_name} ${(supplementalData as NpiProvider).basic.last_name}`
              : (supplementalData as NpiOrganization).basic.organization_name}
          </Typography>
        </Tooltip>
      )}
    </>
  );
};
