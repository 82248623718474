import { GridColDef } from "@mui/x-data-grid";
import { MedicareRatesCell } from "./MedicareRatesCell";
import { NegotiatedRatesCell } from "./NegotiatedRatesCell";
import { TechnicalRatesCell } from "./TechnicalRatesCell";
import { ProfessionalRatesCell } from "./ProfessionalRatesCell";

export const ColumnsConfig: GridColDef[] = [
  { field: "billing_code_type", headerName: "Type", width: 100 },
  { field: "billing_code", headerName: "Code", width: 150 },
  {
    field: "negotiation_arrangement",
    headerName: "Negotiation Arrangement",
    width: 40,
  },
  { field: "name", headerName: "Name", width: 250 },
  {
    field: "description",
    headerName: "Description",
    width: 300,
    hideable: true,
  },
  {
    field: "medicare_data",
    headerName: "Medicare Rates",
    width: 200,
    renderCell: (params) => <MedicareRatesCell value={params.value} />,
  },
  {
    field: "negotiated_rates",
    headerName: "Global Rates",
    width: 200,
    renderCell: (params) => <NegotiatedRatesCell value={params.value} />,
  },
  {
    field: "technical_rates",
    headerName: "Technical / Facility Rates",
    width: 200,
    renderCell: (params) => <TechnicalRatesCell value={params.value} />,
  },
  {
    field: "professional_rates",
    headerName: "Professional Rates",
    width: 200,
    renderCell: (params) => <ProfessionalRatesCell value={params.value} />,
  },
  {
    field: "parent_file_uuid",
    headerName: "Parent File UUID",
    width: 200,
    hideable: true,
  },
];
