import React from "react";
import TabPanel from "../components/TabPanel";
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { DashboardQuickCards } from "../components/dashboard/DashboardQuickCards";
import { DashboardBigCharts } from "../components/dashboard/DashboardBigCharts";

const Home: React.FC = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Box pl={2} pt={2} mb={2}>
        <Breadcrumbs>
          <Typography>Dashboard</Typography>
          <Typography>Home</Typography>
        </Breadcrumbs>
        <Typography fontSize={16} fontWeight={700} mt={2}>
          Overview
        </Typography>
        <DashboardQuickCards />
        <DashboardBigCharts />
      </Box>
      <Typography fontSize={16} fontWeight={700} mt={2} mb={2} pl={2}>
        Details
      </Typography>
      <Divider />
      <TabPanel />
    </Container>
  );
};

export default Home;
