import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Divider,
  Grid,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavIconLink from "../NavIconLink";
import { Download, OpenInNew } from "@mui/icons-material";
import { useSearch } from "../../SearchContext";
import { useAuth } from "../../AuthContext";
import { useParams } from "react-router-dom";

interface ReportingPlan {
  id: number;
  plan_name: string;
  plan_id_type: string;
  plan_id: string;
  plan_market_type: string;
  provider_id: number;
  provider: {
    id: number;
    name: string;
    uuid: string;
  } | null;
  files: {
    id: number;
    url: string;
    url_expires: string;
    s3_location: string;
    status: string;
    validated: boolean;
  }[];
}

const ColumnsConfig: GridColDef[] = [
  {
    field: "id",
    headerName: "",
    width: 75,
    maxWidth: 75,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "100%",
        }}
      >
        <NavIconLink
          to={`/dashboard/reporting_plans/${params.value}`}
          icon={<Download fontSize="small" />}
        />
        <NavIconLink
          to={`/dashboard/reporting_plans/${params.value}`}
          icon={<OpenInNew fontSize="small" />}
        />
      </Box>
    ),
  },
  {
    field: "provider",
    headerName: "Provider",
    width: 100,
    renderCell: (params) => {
      const provider = params.value as { id: number; name: string };
      return (
        <Tooltip title={provider.name}>
          <Typography variant="body2" noWrap>
            {provider.name}
          </Typography>
        </Tooltip>
      );
    },
  },
  { field: "plan_id", headerName: "Plan", width: 150, hideable: true },
  { field: "plan_id_type", headerName: "Plan ID Type", width: 150 },
  { field: "plan_name", headerName: "Plan Name", width: 350 },
  { field: "provider_id", headerName: "Provider Name", width: 150 },
  { field: "plan_market_type", headerName: "Plan Market Type", width: 150 },
];

interface ProviderReportingPlansTableProps {
  providerId: number;
}
const ProviderReportingPlansTable: React.FC<
  ProviderReportingPlansTableProps
> = ({ providerId }) => {
  const { token } = useAuth();
  const { uuid } = useParams<{ uuid: string }>();

  const { searchQuery, searchHistory } = useSearch();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [rowCount, setRowCount] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<ReportingPlan | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const fetchPlans = (page: number, pageSize: number, keyword: string) => {
    fetch(
      `${window.location.origin}/api/reporting_plans?keyword=${keyword}&page=${page + 1}&per_page=${pageSize}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setRowCount(data.total);

        if (data.items.length > 0) {
          setColumns(ColumnsConfig);
        }

        const rows = data.items.map((item: ReportingPlan) => ({
          id: item.id,
          ...Object.fromEntries(
            Object.entries(item).filter(([key]) => key !== "id")
          ),
        }));
        setRows(
          rows.filter((row: ReportingPlan) => row.provider_id === providerId)
        );
      });
  };

  useEffect(() => {
    fetchPlans(paginationModel.page, paginationModel.pageSize, searchQuery);
  }, [paginationModel, searchHistory]);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleRowClick = (params: GridRowParams) => {
    setSelectedPlan(params.row as ReportingPlan);
    setDrawerOpen(true);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        checkboxSelection={true}
        rowHeight={30}
        onRowClick={handleRowClick}
      />
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: "50vw",
            padding: 2,
          }}
          role="presentation"
        >
          <IconButton onClick={toggleDrawer(false)} sx={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" component="h2" gutterBottom>
            Reporting Plan Details
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {selectedPlan && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="textSecondary">
                    ID:
                  </Typography>
                  <Typography variant="body1">{selectedPlan.id}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Name:
                  </Typography>
                  <Typography variant="body1">
                    {selectedPlan.plan_name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Type:
                  </Typography>
                  <Typography variant="body1">
                    {selectedPlan.plan_id_type}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Market Type:
                  </Typography>
                  <Typography variant="body1">
                    {selectedPlan.plan_market_type}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Provider ID:
                  </Typography>
                  <Typography variant="body1">
                    {selectedPlan.provider_id}
                  </Typography>
                </Grid>
                {selectedPlan.provider && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Provider Name:
                      </Typography>
                      <Typography variant="body1">
                        {selectedPlan.provider.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Provider UUID:
                      </Typography>
                      <Typography variant="body1">
                        {selectedPlan.provider.uuid}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Files
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  {selectedPlan.files &&
                    selectedPlan.files.map((file) => (
                      <Card key={file.id} sx={{ mb: 2 }}>
                        <CardContent>
                          <Typography variant="subtitle1" color="textSecondary">
                            File ID:
                          </Typography>
                          <Typography variant="body1">{file.id}</Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            URL:
                          </Typography>
                          <Typography variant="body1">{file.url}</Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            URL Expires:
                          </Typography>
                          <Typography variant="body1">
                            {file.url_expires}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            S3 Location:
                          </Typography>
                          <Typography variant="body1">
                            {file.s3_location}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            Status:
                          </Typography>
                          <Typography variant="body1">{file.status}</Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            Validated:
                          </Typography>
                          <Typography variant="body1">
                            {file.validated ? "Yes" : "No"}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default ProviderReportingPlansTable;
