import { Box, Chip, Stack } from "@mui/material";
import { NegotiatedRate } from "../../interfaces";

// Helper functions for mean, median, and mode
const calculateMean = (rates: number[]) => {
  const sum = rates.reduce((a, b) => a + b, 0);
  return (sum / rates.length).toFixed(2);
};

// Custom cell renderer for negotiated rates
export const TechnicalRatesCell: React.FC<{ value: NegotiatedRate[] }> = ({
  value,
}) => {
  const rates = value
    .flatMap((rateObj) => rateObj.negotiated_prices)
    .filter(
      (price) =>
        price.billing_code_modifier &&
        price.billing_code_modifier.length > 0 &&
        price.billing_code_modifier[0] === "TC" &&
        price
    )
    .map((price) => price.negotiated_rate);

  if (!rates.length) return <span>---</span>;

  const mean = calculateMean(rates);
  // const median = calculateMedian(rates); // Available for later use
  // const mode = calculateMode(rates); // Available for later use
  // const lowest = Math.min(...rates).toFixed(2);
  // const highest = Math.max(...rates).toFixed(2);

  // const numPrices = value.reduce(
  //   (total, rateObj) => total + rateObj.negotiated_prices.length,
  //   0
  // );

  return (
    <Box display="flex" alignItems="center" height="100%">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Chip label={`Avg: $${mean}`} color="success" size="small" />
        {/* <Chip label={`Low: $${lowest}`} color="success" size="small" /> */}
        {/* <Chip label={`High: $${highest}`} color="warning" size="small" /> */}
      </Stack>
    </Box>
  );
};
