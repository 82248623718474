import React, { useEffect, useState, useCallback } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { Box, Container } from "@mui/material";
import {
  OpenSearchInNetworkRecord,
  OpenSearchInNetworkResponse,
} from "../../interfaces";
import { useSearch } from "../../SearchContext";
import debounce from "lodash.debounce";
import { NegotiatedRatesCell } from "./NegotiatedRatesCell";
import { Download, OpenInNew } from "@mui/icons-material";
import NavIconLink from "../NavIconLink";

import { MedicareRatesCell } from "./MedicareRatesCell";
import { useAuth } from "../../AuthContext";

const ColumnsConfig: GridColDef[] = [
  {
    field: "id",
    headerName: "",
    width: 75,
    maxWidth: 75,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "100%",
        }}
      >
        <NavIconLink
          to={`/dashboard/code/${params.value}`}
          icon={<Download fontSize="small" />}
        />
        <NavIconLink
          to={`/dashboard/code/${params.value}`}
          icon={<OpenInNew fontSize="small" />}
        />
      </Box>
    ),
  },
  { field: "billing_code_type", headerName: "Type", width: 100 },
  { field: "billing_code", headerName: "Code", width: 150 },
  {
    field: "negotiation_arrangement",
    headerName: "Negotiation Arrangement",
    width: 200,
  },
  { field: "name", headerName: "Name", width: 200 },
  {
    field: "description",
    headerName: "Description",
    width: 300,
    hideable: true,
  },
  {
    field: "medicare_data",
    headerName: "Medicare Rates",
    width: 200,
    renderCell: (params) => <MedicareRatesCell value={params.value} />,
  },
  {
    field: "negotiated_rates",
    headerName: "Negotiated Rates",
    width: 600,
    renderCell: (params) => <NegotiatedRatesCell value={params.value} />,
  },
  {
    field: "parent_file_uuid",
    headerName: "Parent File UUID",
    width: 200,
    hideable: true,
  },
];

const InNetworkTable: React.FC = () => {
  const { token } = useAuth();
  const { searchQuery, searchHistory } = useSearch();
  const [records, setRecords] = useState<OpenSearchInNetworkRecord[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [rowCount, setRowCount] = useState(0);
  const fetchRecords = useCallback(
    debounce(
      (page: number, pageSize: number, keyword: string, token: string) => {
        fetch(
          `${window.location.origin}/api/search/in-network-v1/keyword_search?keyword=${keyword}&page=${page + 1}&size=${pageSize}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data: OpenSearchInNetworkResponse) => {
            setRecords(data.results);
            setRowCount(data.total);

            // Set columns based on the keys of the first object in the response
            if (data.results.length > 0) {
              setColumns(ColumnsConfig);
            }

            // Set rows based on the response data
            const rows = data.results.map(
              (item: OpenSearchInNetworkRecord) => ({
                id: item.uuid,
                ...Object.fromEntries(
                  Object.entries(item).filter(([key]) => key !== "uuid")
                ),
              })
            );
            setRows(rows);
          });
      },
      300
    ),
    [token]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchRecords(
      paginationModel.page,
      paginationModel.pageSize,
      searchQuery,
      token
    );
  }, [paginationModel, searchHistory, fetchRecords, token]);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <div style={{ display: "flex", flexDirection: "column", height: 500 }}>
        <DataGrid
          loading={records.length === 0}
          rows={rows}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          checkboxSelection={true}
          rowHeight={40}
          columnVisibilityModel={{
            parent_file_uuid: false,
            description: false,
          }}
        />
      </div>
    </Container>
  );
};

export default InNetworkTable;
