import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { useDemoRouter } from "@toolpad/core/internal";
import { SearchProvider } from "../SearchContext";
import { AppTheme } from "../Theme";
import VeracityRoutes from "../routes/VeracityRoutes";

interface DemoProps {
  window?: () => Window;
}

export function VeracityLayout(props: DemoProps) {
  const { window } = props;

  const router = useDemoRouter("");

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  // const { isAuthenticated } = useAuth(); // Use the authentication context

  // if (!isAuthenticated) {
  //   return <SignInSide />;
  // }

  return (
    <SearchProvider>
      <AppProvider
        router={router}
        theme={AppTheme}
        window={demoWindow}
        branding={{
          logo: <img src="logo-veracity.png" alt="logo" />,
          title: "Veracity Benefits",
        }}
      >
        <DashboardLayout hideNavigation allowFullScreen loading={"lazy"}>
          {VeracityRoutes()}
        </DashboardLayout>
      </AppProvider>
    </SearchProvider>
  );
}

function App() {
  return (
    <SearchProvider>
      <VeracityLayout />
    </SearchProvider>
  );
}

export default App;
