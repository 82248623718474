export class ApiClient {
  private token: string;

  constructor(token: string) {
    this.token = token;
  }

  private getDefaultHeaders(): HeadersInit {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  public async request<T>(url: string, options?: RequestInit): Promise<T> {
    const { headers, ...restOptions } = options || {};

    const response = await fetch(url, {
      ...restOptions,
      headers: {
        ...this.getDefaultHeaders(),
        ...(headers || {}),
      },
    });

    if (!response.ok) {
      const errorBody = await response.json().catch(() => ({}));
      throw new Error(
        `HTTP error! Status: ${response.status}, Body: ${JSON.stringify(
          errorBody
        )}`
      );
    }

    return response.json() as Promise<T>;
  }
}
