import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import DashboardApp from "./miniapps/DashboardApp";
import ShopperApp from "./miniapps/ShopperApp";
import "./App.css";
import Landing from "./pages/Landing";
import MarketingPage from "./miniapps/marketing-page/MarketingPage";
import VeracityApp from "./miniapps/VeracityApp";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/dashboard/*" element={<DashboardApp />} />
          <Route path="/shopper/*" element={<ShopperApp />} />
          <Route path="/veracity/*" element={<VeracityApp />} />
          {/* Add more routes as needed */}
          <Route
            path="/"
            element={<MarketingPage disableCustomTheme={false} />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
