import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Box,
  Button,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export interface NegotiatedRate {
  negotiated_type: string;
  negotiated_rate: number;
  expiration_date: string;
  service_code: string[];
  billing_class: string;
  billing_code_modifier: string[];
  additional_information: string;
}

export interface OpenSearchInNetworkRecord {
  md5_checksum: string;
  negotiation_arrangement: string;
  name: string;
  billing_code_type: string;
  billing_code_type_version: string;
  billing_code: string;
  description: string;
  negotiated_rates: NegotiatedRate[];
  uuid: string;
  parent_file_uuid: string;
}

const initialCartItems: OpenSearchInNetworkRecord[] = [
  {
    md5_checksum: "abc123",
    negotiation_arrangement: "Direct",
    name: "MRI Scan",
    billing_code_type: "CPT",
    billing_code_type_version: "2021",
    billing_code: "70551",
    description: "Magnetic Resonance Imaging",
    negotiated_rates: [
      {
        negotiated_type: "Fee-for-service",
        negotiated_rate: 500,
        expiration_date: "2023-12-31",
        service_code: ["MRI"],
        billing_class: "Radiology",
        billing_code_modifier: ["26"],
        additional_information: "Includes contrast",
      },
    ],
    uuid: "uuid1",
    parent_file_uuid: "parent_uuid1",
  },
  {
    md5_checksum: "def456",
    negotiation_arrangement: "Direct",
    name: "Blood Test",
    billing_code_type: "CPT",
    billing_code_type_version: "2021",
    billing_code: "80050",
    description: "General Health Panel",
    negotiated_rates: [
      {
        negotiated_type: "Fee-for-service",
        negotiated_rate: 50,
        expiration_date: "2023-12-31",
        service_code: ["Blood Test"],
        billing_class: "Laboratory",
        billing_code_modifier: [],
        additional_information: "",
      },
    ],
    uuid: "uuid2",
    parent_file_uuid: "parent_uuid2",
  },
  {
    md5_checksum: "ghi789",
    negotiation_arrangement: "Direct",
    name: "X-Ray",
    billing_code_type: "CPT",
    billing_code_type_version: "2021",
    billing_code: "71020",
    description: "Chest X-Ray",
    negotiated_rates: [
      {
        negotiated_type: "Fee-for-service",
        negotiated_rate: 100,
        expiration_date: "2023-12-31",
        service_code: ["X-Ray"],
        billing_class: "Radiology",
        billing_code_modifier: [],
        additional_information: "",
      },
    ],
    uuid: "uuid3",
    parent_file_uuid: "parent_uuid3",
  },
];

const ShoppingCart: React.FC = () => {
  const [cartItems, setCartItems] =
    React.useState<OpenSearchInNetworkRecord[]>(initialCartItems);

  const handleRemoveItem = (uuid: string) => {
    setCartItems(cartItems.filter((item) => item.uuid !== uuid));
  };

  const getTotalPrice = () => {
    return cartItems.reduce(
      (total, item) => total + item.negotiated_rates[0].negotiated_rate,
      0
    );
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 2 }}>
        <List>
          {cartItems.map((item) => (
            <React.Fragment key={item.uuid}>
              <ListItem>
                <ListItemText
                  primary={item.name}
                  secondary={`Price: $${item.negotiated_rates[0].negotiated_rate}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveItem(item.uuid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Typography variant="h6">Total: ${getTotalPrice()}</Typography>
          <Button variant="contained" color="primary">
            Proceed to Checkout
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ShoppingCart;
