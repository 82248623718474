import React, { useState } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useInitial } from "../InitialContext";

function InsuranceCarrierSelector() {
  const [loading, setLoading] = useState(false);
  const { initialData } = useInitial();

  const handleSelectionChange = (
    event: React.SyntheticEvent,
    newValue: any | null
  ) => {
    // console.log("Selected location:", newValue);
    // if (newValue && newValue.zip === "all") {
    //   addSelectedZipCodes([]);
    // }
    // addSelectedZipCodes(newValue ? [newValue] : []);
  };

  return (
    <Autocomplete
      style={{ width: "100%" }}
      size="small"
      multiple
      options={initialData?.health_insurance_providers || []}
      getOptionLabel={(option) => `${option.name}`}
      defaultValue={initialData?.health_insurance_providers || []}
      filterSelectedOptions
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Insurance Carriers"
          placeholder=""
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default InsuranceCarrierSelector;
