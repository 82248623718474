import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

interface OverlayLinkProps {
  to: string;
}

const OverlayLink: React.FC<OverlayLinkProps> = ({ to }) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        navigate(to);
      }}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        textDecoration: "none",
        color: "inherit",
        cursor: "pointer", // Add cursor pointer to indicate clickable area
      }}
    />
  );
};

export default OverlayLink;
