import * as React from "react";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { useDemoRouter } from "@toolpad/core/internal";
import { SearchProvider } from "../SearchContext";
import { AuthProvider, useAuth } from "../AuthContext";
// import BlockerPrompt from "../BlockerPrompt";
import { NAVIGATION } from "../components/MainNavLeftMenu";
import { AppTheme } from "../Theme";
import { Search } from "../components/NavSearch";
import DashboardRoutes from "../routes/DashboardRoutes";
import { InitialProvider } from "../InitialContext";
// import { SignIn } from "../pages/auth/signin";
import SignInSide from "./sign-in-side/SignInSide";

interface DemoProps {
  window?: () => Window;
}

export function DashboardLayoutBasic(props: DemoProps) {
  const { window } = props;

  const router = useDemoRouter("");

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  const { isAuthenticated } = useAuth(); // Use the authentication context

  if (!isAuthenticated) {
    return <SignInSide />; // Show the blocker prompt if not authenticated
    // return SignIn();
  }

  return (
    <InitialProvider>
      <SearchProvider>
        <AppProvider
          navigation={NAVIGATION}
          router={router}
          theme={AppTheme}
          window={demoWindow}
          branding={{
            logo: <img src="logo.png" alt="logo" />,
            title: "Conduit Health",
          }}
        >
          <DashboardLayout
            defaultSidebarCollapsed
            slots={{ toolbarActions: Search }}
            allowFullScreen
            loading={"lazy"}
          >
            {DashboardRoutes()}
          </DashboardLayout>
        </AppProvider>
      </SearchProvider>
    </InitialProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <DashboardLayoutBasic />
    </AuthProvider>
  );
}

export default App;
