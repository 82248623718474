import React from "react";
import { HomeHeaderSVG } from "../components/headers/HomeGeaderSVG";
import { Typography } from "@mui/material";

const Settings: React.FC = () => {
  return (
    <>
      <HomeHeaderSVG />
      <Typography pt={3} pl={2} variant="h4" color="white">
        Account Settings
      </Typography>
    </>
  );
};

export default Settings;
