import { Container } from "@mui/material";
import React from "react";
import { HomeHeaderSVG } from "../../components/headers/HomeGeaderSVG";
import InNetworkShopSearchResults from "../../components/shopper/InNetworkShopSearchResults";

const ShopPage: React.FC = () => {
  return (
    <Container maxWidth={false}>
      <HomeHeaderSVG />
      {/* SEARCH INPUT MOBILE*/}
      {/* FOOTER NAV */}
      <InNetworkShopSearchResults />
    </Container>
  );
};

export default ShopPage;
