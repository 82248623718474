export const PlacesOfService = [
  {
    "Place of Service Code(s)": 1,
    "Place of Service Name": "Pharmacy**",
    "Place of Service Description":
      "A facility or location where drugs and other medically related items and services are sold",
  },
  {
    "Place of Service Code(s)": 2,
    "Place of Service Name": "Telehealth Provided Other than in Patient’s Home",
    "Place of Service Description":
      "The location where health services and health related services are provided or received",
  },
  {
    "Place of Service Code(s)": 3,
    "Place of Service Name": "School",
    "Place of Service Description":
      "A facility whose primary purpose is education. (Effective January 1",
  },
  {
    "Place of Service Code(s)": 4,
    "Place of Service Name": "Homeless Shelter",
    "Place of Service Description":
      "A facility or location whose primary purpose is to provide temporary housing to homeless individuals (e.g.",
  },
  {
    "Place of Service Code(s)": 5,
    "Place of Service Name": "Indian Health Service Free- standing Facility",
    "Place of Service Description": "A facility or location",
  },
  {
    "Place of Service Code(s)": 6,
    "Place of Service Name": "Indian Health Service Provider- based Facility",
    "Place of Service Description": "A facility or location",
  },
  {
    "Place of Service Code(s)": 7,
    "Place of Service Name": "Tribal 638 Free- standing Facility",
    "Place of Service Description":
      "A facility or location owned and operated by a federally recognized American Indian or Alaska Native tribe or tribal organization under a 638 agreement",
  },
  {
    "Place of Service Code(s)": 8,
    "Place of Service Name": "Tribal 638 Provider-based Facility",
    "Place of Service Description":
      "A facility or location owned and operated by a federally recognized American Indian or Alaska Native tribe or tribal organization under a 638 agreement",
  },
  {
    "Place of Service Code(s)": 9,
    "Place of Service Name": "Prison/Correctional Facility",
    "Place of Service Description": "A prison",
  },
  {
    "Place of Service Code(s)": 10,
    "Place of Service Name": "Telehealth Provided in Patient’s Home",
    "Place of Service Description":
      "The location where health services and health related services are provided or received",
  },
  {
    "Place of Service Code(s)": 11,
    "Place of Service Name": "Office",
    "Place of Service Description": "Location",
  },
  {
    "Place of Service Code(s)": 12,
    "Place of Service Name": "Home",
    "Place of Service Description": "Location",
  },
  {
    "Place of Service Code(s)": 13,
    "Place of Service Name": "Assisted Living Facility",
    "Place of Service Description":
      "Congregate residential facility with self- contained living units providing assessment of each resident's needs and on-site support 24 hours a day",
  },
  {
    "Place of Service Code(s)": 14,
    "Place of Service Name": "Group Home*",
    "Place of Service Description": "A residence",
  },
  {
    "Place of Service Code(s)": 15,
    "Place of Service Name": "Mobile Unit",
    "Place of Service Description":
      "A facility/unit that moves from place-to-place equipped to provide preventive",
  },
  {
    "Place of Service Code(s)": 16,
    "Place of Service Name": "Temporary Lodging",
    "Place of Service Description":
      "A short-term accommodation such as a hotel",
  },
  {
    "Place of Service Code(s)": 17,
    "Place of Service Name": "Walk-in Retail Health Clinic",
    "Place of Service Description": "A walk-in health clinic",
  },
  {
    "Place of Service Code(s)": 18,
    "Place of Service Name": "Place of Employment- Worksite",
    "Place of Service Description": "A location",
  },
  {
    "Place of Service Code(s)": 19,
    "Place of Service Name": "Off Campus- Outpatient Hospital",
    "Place of Service Description":
      "A portion of an off-campus hospital provider based department which provides diagnostic",
  },
  {
    "Place of Service Code(s)": 20,
    "Place of Service Name": "Urgent Care Facility",
    "Place of Service Description": "Location",
  },
  {
    "Place of Service Code(s)": 21,
    "Place of Service Name": "Inpatient Hospital",
    "Place of Service Description": "A facility",
  },
  {
    "Place of Service Code(s)": 22,
    "Place of Service Name": "On Campus- Outpatient Hospital",
    "Place of Service Description":
      "A portion of a hospital’s main campus which provides diagnostic",
  },
  {
    "Place of Service Code(s)": 23,
    "Place of Service Name": "Emergency Room – Hospital",
    "Place of Service Description":
      "A portion of a hospital where emergency diagnosis and treatment of illness or injury is provided.",
  },
  {
    "Place of Service Code(s)": 24,
    "Place of Service Name": "Ambulatory Surgical Center",
    "Place of Service Description": "A freestanding facility",
  },
  {
    "Place of Service Code(s)": 25,
    "Place of Service Name": "Birthing Center",
    "Place of Service Description": "A facility",
  },
  {
    "Place of Service Code(s)": 26,
    "Place of Service Name": "Military Treatment Facility",
    "Place of Service Description":
      "A medical facility operated by one or more of the Uniformed Services. Military Treatment Facility (MTF) also refers to certain former U.S. Public Health Service (USPHS) facilities now designated as Uniformed Service Treatment Facilities (USTF).",
  },
  {
    "Place of Service Code(s)": 27,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 28,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 29,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 30,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 31,
    "Place of Service Name": "Skilled Nursing Facility",
    "Place of Service Description":
      "A facility which primarily provides inpatient skilled nursing care and related services to patients who require medical",
  },
  {
    "Place of Service Code(s)": 32,
    "Place of Service Name": "Nursing Facility",
    "Place of Service Description":
      "A facility which primarily provides to residents skilled nursing care and related services for the rehabilitation of injured",
  },
  {
    "Place of Service Code(s)": 33,
    "Place of Service Name": "Custodial Care Facility",
    "Place of Service Description": "A facility which provides room",
  },
  {
    "Place of Service Code(s)": 34,
    "Place of Service Name": "Hospice",
    "Place of Service Description": "A facility",
  },
  {
    "Place of Service Code(s)": 35,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 36,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 37,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 38,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 39,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 40,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 41,
    "Place of Service Name": "Ambulance – Land",
    "Place of Service Description": "A land vehicle specifically designed",
  },
  {
    "Place of Service Code(s)": 42,
    "Place of Service Name": "Ambulance – Air or Water",
    "Place of Service Description":
      "An air or water vehicle specifically designed",
  },
  {
    "Place of Service Code(s)": 43,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 44,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 45,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 46,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 47,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 48,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 49,
    "Place of Service Name": "Independent Clinic",
    "Place of Service Description": "A location",
  },
  {
    "Place of Service Code(s)": 50,
    "Place of Service Name": "Federally Qualified Health Center",
    "Place of Service Description":
      "A facility located in a medically underserved area that provides Medicare beneficiaries preventive primary medical care under the general direction of a physician.",
  },
  {
    "Place of Service Code(s)": 51,
    "Place of Service Name": "Inpatient Psychiatric Facility",
    "Place of Service Description":
      "A facility that provides inpatient psychiatric services for the diagnosis and treatment of mental illness on a 24-hour basis",
  },
  {
    "Place of Service Code(s)": 52,
    "Place of Service Name": "Psychiatric Facility – Partial Hospitalization",
    "Place of Service Description":
      "A facility for the diagnosis and treatment of mental illness that provides a planned therapeutic program for patients who do not require full time hospitalization",
  },
  {
    "Place of Service Code(s)": 53,
    "Place of Service Name": "Community Mental Health Center",
    "Place of Service Description":
      "A facility that provides the following services: outpatient services",
  },
  {
    "Place of Service Code(s)": 54,
    "Place of Service Name":
      "Intermediate Care Facility/Individuals with Intellectual Disabilities",
    "Place of Service Description":
      "A facility which primarily provides health- related care and services above the level of custodial care to individuals but does not provide the level of care or treatment available in a hospital or SNF.",
  },
  {
    "Place of Service Code(s)": 55,
    "Place of Service Name": "Residential Substance Abuse Treatment Facility",
    "Place of Service Description":
      "A facility which provides treatment for substance (alcohol and drug) abuse to live-in residents who do not require acute medical care. Services include individual and group therapy and counseling",
  },
  {
    "Place of Service Code(s)": 56,
    "Place of Service Name": "Psychiatric Residential Treatment Center",
    "Place of Service Description":
      "A facility or distinct part of a facility for psychiatric care which provides a total 24-hour therapeutically planned and professionally staffed group living and learning environment.",
  },
  {
    "Place of Service Code(s)": 57,
    "Place of Service Name":
      "Non-residential Substance Abuse Treatment Facility",
    "Place of Service Description":
      "A location which provides treatment for substance (alcohol and drug) abuse on an ambulatory basis. Services include individual and group therapy and counseling",
  },
  {
    "Place of Service Code(s)": 58,
    "Place of Service Name": "Non-residential Opioid Treatment Facility",
    "Place of Service Description":
      "A location that provides treatment for opioid use disorder on an ambulatory basis. Services include methadone and other forms of Medication Assisted Treatment (MAT). (Effective January 1",
  },
  {
    "Place of Service Code(s)": 59,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 60,
    "Place of Service Name": "Mass Immunization Center",
    "Place of Service Description":
      "A location where providers administer pneumococcal pneumonia and influenza virus vaccinations and submit these services as electronic media claims",
  },
  {
    "Place of Service Code(s)": 61,
    "Place of Service Name": "Comprehensive Inpatient Rehabilitation Facility",
    "Place of Service Description":
      "A facility that provides comprehensive rehabilitation services under the supervision of a physician to inpatients with physical disabilities. Services include physical therapy",
  },
  {
    "Place of Service Code(s)": 62,
    "Place of Service Name": "Comprehensive Outpatient Rehabilitation Facility",
    "Place of Service Description":
      "A facility that provides comprehensive rehabilitation services under the supervision of a physician to outpatients with physical disabilities. Services include physical therapy",
  },
  {
    "Place of Service Code(s)": 63,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 64,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 65,
    "Place of Service Name": "End-Stage Renal Disease Treatment Facility",
    "Place of Service Description": "A facility other than a hospital",
  },
  {
    "Place of Service Code(s)": 66,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 67,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 68,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 69,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 70,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 71,
    "Place of Service Name": "Public Health Clinic",
    "Place of Service Description":
      "A facility maintained by either State or local health departments that provides ambulatory primary medical care under the general direction of a physician.",
  },
  {
    "Place of Service Code(s)": 72,
    "Place of Service Name": "Rural Health Clinic",
    "Place of Service Description":
      "A certified facility which is located in a rural medically underserved area that provides ambulatory primary medical care under the general direction of a physician.",
  },
  {
    "Place of Service Code(s)": 73,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 74,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 75,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 76,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 77,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 78,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 79,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 80,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 81,
    "Place of Service Name": "Independent Laboratory",
    "Place of Service Description":
      "A laboratory certified to perform diagnostic and/or clinical tests independent of an institution or a physician's office.",
  },
  {
    "Place of Service Code(s)": 82,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 83,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 84,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 85,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 86,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 87,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 88,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 89,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 90,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 91,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 92,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 93,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 94,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 95,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 96,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 97,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 98,
    "Place of Service Name": "Unassigned",
    "Place of Service Description": "N/A",
  },
  {
    "Place of Service Code(s)": 99,
    "Place of Service Name": "Other Place of Service",
    "Place of Service Description":
      "Other place of service not identified above.",
  },
];

export const matchedPlaces = (codes: string[]) => {
  const items = PlacesOfService.filter((place) =>
    codes.includes(
      String(place["Place of Service Code(s)"]).padStart(2, "0") // Ensure codes are 2-digit strings
    )
  );
  const res = items.map((item) => ({
    name: item["Place of Service Name"],
    code: item["Place of Service Code(s)"],
  }));
  return res.length >= 99 ? [{ name: "all", code: "all" }] : res;
};
