import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { useDemoRouter } from "@toolpad/core/internal";
import { SearchProvider } from "../SearchContext";
import { AppTheme } from "../Theme";
import { Search } from "../components/NavSearch";
import ShopperRoutes from "../routes/ShopperRoutes";
import { ShopperLeftNav } from "../components/ShopperLeftMenu";

interface WindowProps {
  window?: () => Window;
}

export function ShopperLayout(props: WindowProps) {
  const { window } = props;

  const router = useDemoRouter("");

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  // const { isAuthenticated } = useAuth(); // Use the authentication context

  // if (!isAuthenticated) {
  //   return <SignInSide />;
  //   // Show the blocker prompt if not authenticated

  //   // return SignIn();
  // }

  return (
    <SearchProvider>
      <AppProvider
        navigation={ShopperLeftNav}
        router={router}
        theme={AppTheme}
        window={demoWindow}
        branding={{
          logo: (
            <img
              id="BrandLogo"
              src="https://mui.com/static/logo.png"
              alt="MUI logo"
            />
          ),
          title: "",
        }}
      >
        <DashboardLayout
          defaultSidebarCollapsed
          slots={{ toolbarActions: Search }}
        >
          {ShopperRoutes()}
        </DashboardLayout>
      </AppProvider>
    </SearchProvider>
  );
}

function App() {
  return <ShopperLayout />;
}

export default App;
