import { Box, Chip, Stack } from "@mui/material";
import { MedicareCodePrice } from "../../interfaces";

// Helper functions for mean, median, and mode
const calculateMean = (rates: number[]) => {
  const sum = rates.reduce((a, b) => a + b, 0);
  return (sum / rates.length).toFixed(2);
};

// Custom cell renderer for negotiated rates
export const MedicareRatesCell: React.FC<{ value: MedicareCodePrice[] }> = ({
  value,
}) => {
  const rates = value.map((rate) => parseFloat(rate.fac_price));
  let mean = calculateMean(rates);
  if (value.length === 0) {
    mean = "n/a";
  }

  return (
    <Box display="flex" alignItems="center" height="100%">
      <Stack direction="row" alignItems="center" spacing={1}>
        {mean === "n/a" ? (
          <>---</>
        ) : (
          <Chip
            label={`Avg: $${mean ? mean : "n/a"}`}
            color="error"
            size="small"
          />
        )}
      </Stack>
    </Box>
  );
};
