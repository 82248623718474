import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user]);

  if (!user) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default RequireAuth;
