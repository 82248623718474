import React from "react";
import { Routes, Route } from "react-router-dom";
import MarketingPage from "./miniapps/marketing-page/MarketingPage";
import { SignIn } from "./pages/auth/signin";
import SignInSide from "./miniapps/sign-in-side/SignInSide";

const UnauthenticatedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MarketingPage disableCustomTheme={false} />} />
      <Route path="/signin" element={<SignInSide />} />
    </Routes>
  );
};

export default UnauthenticatedRoutes;
