import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Breadcrumbs,
  CircularProgress,
} from "@mui/material";
import { useSearch } from "../SearchContext";
import { GoogleMapComponent } from "../components/maps/GoogleMapComponent";
import HealthProviderTable from "../components/tables/HealthProviderTable";

const CareProviders: React.FC = () => {
  const { selectedZipCodes } = useSearch();
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Container maxWidth={false} disableGutters>
      <Box pl={2} pt={2} mb={2}>
        <Breadcrumbs>
          <Typography>Dashboard</Typography>
          <Typography>Care Providers</Typography>
        </Breadcrumbs>
        {loading && (
          <Box mt={3}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <GoogleMapComponent
        apiKey={"AIzaSyCXGbUGi4KyI85-PzNNcky5MpBcG0_iHyU"}
        zoom={10}
        initialPosition={{ lat: 35.9250637, lng: -86.8688899 }}
        selectedZipCodes={selectedZipCodes}
      />

      <Box pl={2} sx={{ width: "100%", maxWidth: "92vw", overflowX: "hidden" }}>
        <HealthProviderTable />
      </Box>
    </Container>
  );
};

export default CareProviders;
