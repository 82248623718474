export const snakeToHumanReadable = (str: string): string => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const calculateMedian = (rates: number[]) => {
  const sorted = [...rates].sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  return sorted.length % 2 !== 0
    ? sorted[mid].toFixed(2)
    : ((sorted[mid - 1] + sorted[mid]) / 2).toFixed(2);
};

export const calculateMode = (rates: number[]) => {
  const frequency: { [key: number]: number } = {};
  rates.forEach((rate) => (frequency[rate] = (frequency[rate] || 0) + 1));
  const mode = Object.keys(frequency).reduce((a, b) =>
    frequency[parseFloat(a)] > frequency[parseFloat(b)] ? a : b
  );
  return parseFloat(mode).toFixed(2);
};

export const getFileNameFromUrl = (url: string): string => {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  return pathname.substring(pathname.lastIndexOf("/") + 1);
};

export function formatBytes(bytes: number): string {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}
