import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { HealthInsuranceProviderFile } from "../../interfaces/Models";
import { FileDownload } from "@mui/icons-material";
import {
  Avatar,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { getFileNameFromUrl, formatBytes } from "../../utils";

interface FileListProps {
  files: HealthInsuranceProviderFile[];
}

export const FileList: React.FC<FileListProps> = ({ files }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {files &&
        files.sort().map((file) => (
          <>
            <ListItem key={file.id}>
              <ListItemAvatar>
                <Avatar>
                  <FileDownload />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={getFileNameFromUrl(file.url)}
                secondary={
                  <>
                    Status: {file.status}
                    <br />
                    Size: {formatBytes(file.size ? file.size : 0)}
                  </>
                }
              />
              <ButtonGroup>
                <Button size="small" variant="outlined" color="primary">
                  Actions
                </Button>
              </ButtonGroup>
            </ListItem>
          </>
        ))}
    </List>
  );
};
