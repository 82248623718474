import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

interface NavIconLinkProps {
  to: string;
  icon: React.ReactElement;
}

const NavIconLink: React.FC<NavIconLinkProps> = ({ to, icon }) => {
  return (
    <Box
      component={Link}
      to={to}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        color: "inherit",
      }}
    >
      {icon}
    </Box>
  );
};

export default NavIconLink;
