import { Close, FilterListOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  NegotiationArrangements,
  NegotiatedTypes,
  BillingCodeTypes,
  BillingClassTypes,
} from "../../utils/InNetworkEnums";
import { defaultFilters } from "../../utils/SearchFilters";
import InsuranceCarrierSelector from "../InsuranceCarrierSelector";
import LocationSelector from "../LocationSelector";
import ReportingPlanSelector from "../ReportingPlanSelector";
import { useInitial } from "../../InitialContext";
import { useSearch } from "../../SearchContext";

export const VeracitySearchFilters: React.FC = () => {
  //   const { initialData } = useInitial();
  const { filters, setFilters } = useSearch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  return (
    <>
      <Grid
        item
        xs={2}
        sx={{
          ml: "auto",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "30px",
        }}
      >
        <Tooltip title="Filters">
          <IconButton onClick={toggleDrawer(true)}>
            <FilterListOutlined />
          </IconButton>
        </Tooltip>
      </Grid>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 800,
            padding: 2,
            marginTop: "65px",
          }}
          role="presentation"
        >
          <IconButton onClick={toggleDrawer(false)} style={{ float: "right" }}>
            <Close />
          </IconButton>
          <Typography variant="h5" component="h2" mb={4} gutterBottom>
            Search Filters
          </Typography>
          <Grid pt={2} container spacing={2}>
            <Grid item xs={12} md={10}>
              <InsuranceCarrierSelector />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={10}>
              <ReportingPlanSelector />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            {/* <Grid item xs={12} md={10}>
              <LocationSelector />
            </Grid> */}
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={10}>
              <Autocomplete
                size="small"
                value={filters.negotiationArrangements ?? []}
                options={NegotiationArrangements}
                getOptionLabel={(option) => option}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Negotiation Arrangement" />
                )}
                onInputChange={(event, value) => console.log()}
                onKeyDown={(event) => event.key === "Enter" && console.log()}
                onChange={(event, value) =>
                  setFilters({
                    ...filters,
                    negotiationArrangements: value ? value : [],
                  })
                }
                multiple
              />
            </Grid>
            <Grid item xs={12} md={12}></Grid>

            <Grid item xs={12} md={10}>
              <Autocomplete
                size="small"
                value={filters.negotiatedTypes ?? []}
                options={NegotiatedTypes}
                getOptionLabel={(option) => option}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Negotiated Types" />
                )}
                onInputChange={(event, value) => console.log()}
                onKeyDown={(event) => event.key === "Enter" && console.log()}
                onChange={(event, value) =>
                  setFilters({
                    ...filters,
                    negotiatedTypes: value ? value : [],
                  })
                }
                multiple
              />
            </Grid>
            <Grid item xs={12} md={12}></Grid>

            <Grid item xs={12} md={10}>
              <Autocomplete
                size="small"
                value={filters.billingCodeTypes ?? []}
                options={BillingCodeTypes}
                getOptionLabel={(option) => option}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Code Types" />
                )}
                onInputChange={(event, value) => console.log()}
                onKeyDown={(event) => event.key === "Enter" && console.log()}
                onChange={(event, value) =>
                  setFilters({
                    ...filters,
                    billingCodeTypes: value ? value : [],
                  })
                }
                multiple
              />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={10}>
              <Autocomplete
                size="small"
                value={filters.billingClassTypes ?? []}
                options={BillingClassTypes}
                getOptionLabel={(option) => option}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Billing Class" />
                )}
                onInputChange={(event, value) => console.log()}
                onKeyDown={(event) => event.key === "Enter" && console.log()}
                onChange={(event, value) =>
                  setFilters({
                    ...filters,
                    billingClassTypes: value ? value : [],
                  })
                }
                multiple
              />
            </Grid>
          </Grid>

          <ButtonGroup
            style={{ width: "90%", position: "absolute", bottom: 10 }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setFilters(defaultFilters)}
            >
              Reset
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setFilters(filters)}
            >
              Apply
            </Button>
          </ButtonGroup>

          <Button
            size="small"
            style={{ position: "absolute", bottom: 10, right: 30 }}
            variant="outlined"
            color="success"
            onClick={() => setFilters(filters)}
          >
            Save Default
          </Button>
        </Box>
      </Drawer>
    </>
  );
};
