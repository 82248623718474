import { Box, Container, Divider, Typography } from "@mui/material";
import React from "react";
import { HomeHeaderSVG } from "../../components/headers/HomeGeaderSVG";
import { MyHealthPlanGrid } from "../../components/shopper/MyHealthPlanGrid";
// import InNetworkShopSearchResults from "../../components/shopper/InNetworkShopSearchResults";
import PatientEventResults from "../../components/shopper/PatientEventResults";

const ShopperPage: React.FC = () => {
  return (
    <Container maxWidth={false}>
      <HomeHeaderSVG />
      {/* SEARCH INPUT MOBILE*/}
      {/* FOOTER NAV */}
      {/* <ShopperHeaderCards /> */}
      <Box mt={2}>
        <Typography variant="h4" color="white" gutterBottom>
          My Health Plan
        </Typography>
        <MyHealthPlanGrid />
        <Divider />
        <PatientEventResults />
        {/* <InNetworkShopSearchResults /> */}
      </Box>
    </Container>
  );
};

export default ShopperPage;
