import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

interface OverlayLinkProps {
  to: string;
}

const OverlayLink: React.FC<OverlayLinkProps> = ({ to }) => {
  return (
    <Box
      component={Link}
      to={to}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        textDecoration: "none",
        color: "inherit",
      }}
    />
  );
};

export default OverlayLink;
