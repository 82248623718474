import { Box, Chip, Stack, Typography } from "@mui/material";
import { MedicareCodePrice } from "../../interfaces";

// Helper functions for mean, median, and mode
const calculateMean = (rates: number[]) => {
  const sum = rates.reduce((a, b) => a + b, 0);
  return (sum / rates.length).toFixed(2);
};

// Custom cell renderer for negotiated rates
export const MedicareRatesAggregation: React.FC<{
  value: MedicareCodePrice[];
}> = ({ value }) => {
  const rates = value.map((rate) => parseFloat(rate.fac_price));
  let mean = calculateMean(rates);
  if (value.length === 0) {
    mean = "n/a";
  }

  if (!rates.length)
    return (
      <Stack direction="column" alignItems="left" spacing={1}>
        <Typography fontSize={18} fontWeight={700}>
          Medicare Paid
          {/* <InfoRounded fontSize="small" /> */}
        </Typography>
        <Box display="flex" alignItems="center" height="100%">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Chip label={`$0.00`} color="default" />
          </Stack>
        </Box>
      </Stack>
    );

  return (
    <Stack direction="column" alignItems="left" spacing={1}>
      <Typography fontSize={18} fontWeight={700}>
        Medicare Paid
        {/* <InfoRounded fontSize="small" /> */}
      </Typography>
      <Box display="flex" alignItems="center" height="100%">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Chip label={`Paid: $${mean}`} color="error" />
        </Stack>
      </Box>
    </Stack>
  );
};
