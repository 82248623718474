import { GridColDef } from "@mui/x-data-grid";
import { snakeToHumanReadable } from "../../utils";
import { Box } from "@mui/material";
import { Download, OpenInNew } from "@mui/icons-material";
import NavIconLink from "../NavIconLink";

export const AirflowTasksColumnsConfig: GridColDef[] = [
  {
    field: "id",
    headerName: "",
    width: 75,
    maxWidth: 75,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "100%",
        }}
      >
        <NavIconLink
          to={`/reporting_plans/${params.value}`}
          icon={<Download fontSize="small" />}
        />
        <NavIconLink
          to={`/reporting_plans/${params.value}`}
          icon={<OpenInNew fontSize="small" />}
        />
      </Box>
    ),
  },
  {
    field: "task_id",
    headerName: "Task ID",
    width: 200,
    renderCell: (params) => `Process MRF File`,
  },
  {
    field: "depends_on_past",
    headerName: "Depends on Past",
    width: 150,
    type: "boolean",
  },
  {
    field: "priority_weight",
    headerName: "Priority",
    width: 100,
    type: "number",
  },
  {
    field: "retries",
    headerName: "Retries",
    width: 80,
    type: "number",
  },
  {
    field: "retry_delay_seconds",
    headerName: "Retry Delay (s)",
    width: 150,
    //   type: "number",
    renderCell: (params) => params.row.retry_delay.seconds,
  },
  {
    field: "start_date",
    headerName: "Start Date",
    width: 180,
    // type: "dateTime",
  },
  {
    field: "end_date",
    headerName: "End Date",
    width: 180,
    // type: "dateTime",
  },
];
