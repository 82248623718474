import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  List,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import { OpenSearchInNetworkRecord } from "../../interfaces";
import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import NegotiatedPricesTable from "../tables/NegotiatedPricesTable";
import { NegotiatedRatesAggregation } from "../aggregations/NegotiatedRatesAggregation";
import { HealthInsuranceProviderFile } from "../../interfaces/Models";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import PricingChatBot from "../chat/PricingChatBot";
import ReportingPlanDrawerTable from "../tables/ReportingPlanDrawerTable";
import { TechnicalRatesAggregation } from "../aggregations/TechnicalRatesAggregation";
import { ProfessionalRatesAggregation } from "../aggregations/ProfessionalRatesAggregation";
import { MedicareRatesAggregation } from "../aggregations/MedicareRatesAggregation";
interface VeracityCodeDetailsDrawerProps {
  selectedRecord: OpenSearchInNetworkRecord | null;
  open: boolean;
  onClose: () => void;
}

const VeracityCodeDetailsDrawer: React.FC<VeracityCodeDetailsDrawerProps> = ({
  selectedRecord,
  open,
  onClose,
}) => {
  const [showMetadata, setShowMetadata] = useState(false);
  const [providerFile, setProviderFile] =
    useState<HealthInsuranceProviderFile | null>(null);
  const [loadingSupplementalData, setLoadingSupplementalData] = useState(false);
  const [chatDisplay, setChatDisplay] = useState(false);

  const showChat = (open: boolean) => () => {
    console.log("showChat", open);
    setChatDisplay(open);
  };
  useEffect(() => {
    setLoadingSupplementalData(true);
    const fetchInitialData = async () => {
      if (!selectedRecord) {
        return;
      }
      try {
        const response = await fetch(
          `${window.location.origin}/api/provider_file/${selectedRecord?.parent_file_uuid}`
        );
        const data = await response.json();
        setProviderFile(data);
        setLoadingSupplementalData(false);
      } catch (err) {
      } finally {
      }
    };

    fetchInitialData();
  }, [selectedRecord]);

  const handleDownloadPDF = (): void => {
    const element = document.getElementById("pdf-content"); // Target element

    if (!element) {
      console.error("Element with id 'pdf-content' not found");
      return;
    }

    html2canvas(element)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "letter");
        const imgWidth = 216; // Letter width in mm
        const pageHeight = 279; // Letter height in mm
        const margin = 5; // Margin in mm
        const imgHeight =
          (canvas.height * (imgWidth - 2 * margin)) / canvas.width;

        // Add image to the PDF with margins
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          margin,
          imgWidth - 2 * margin,
          imgHeight
        );
        let fileName = selectedRecord?.billing_code || "report";
        pdf.save(`ch-${fileName}.pdf`); // Save the PDF
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: "85vw",
          padding: 2,
          marginTop: 8,
        }}
        role="presentation"
      >
        <IconButton onClick={onClose} sx={{ float: "right" }}>
          <Close />
        </IconButton>
        <Typography
          variant="h5"
          component="h2"
          mb={4}
          ml={1}
          mt={1}
          gutterBottom
        >
          In-Network Record Details
          <Button
            style={{ float: "right", marginRight: 10, marginTop: 0 }}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
          >
            Download Report
          </Button>
          <Button
            style={{ float: "right", marginRight: 10, marginTop: 0 }}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setShowMetadata(!showMetadata)}
          >
            Show Metadata
          </Button>
          <Button
            style={{ float: "right", marginRight: 30, marginTop: 0 }}
            size="small"
            variant={chatDisplay ? "contained" : "outlined"}
            onClick={showChat(!chatDisplay)}
          >
            AI Assistant
          </Button>
        </Typography>
        {/* MAIN */}
        {!chatDisplay && selectedRecord && (
          <Box>
            <div id="pdf-content">
              <Paper elevation={3} style={{ padding: "45px 10px 10px 45px" }}>
                <Typography variant="h6" component="h3" gutterBottom>
                  {selectedRecord.billing_code_type} -{" "}
                  {selectedRecord.billing_code}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {selectedRecord.name}
                </Typography>
                {selectedRecord.name != selectedRecord.description && (
                  <Typography variant="body2" gutterBottom>
                    {selectedRecord.description}
                  </Typography>
                )}
                {/* PLAN / FILE DATA */}
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    {!loadingSupplementalData ? (
                      providerFile && (
                        <>
                          <Typography fontSize={18} mt={2} gutterBottom>
                            <strong>{providerFile.provider?.name}</strong>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <strong>MRF File:</strong>{" "}
                            <a href={providerFile.url} target="_blank">
                              {providerFile.s3_location?.split("/").pop()}
                            </a>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <strong>Identifier:</strong> {providerFile.uuid}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <strong>Status:</strong> {providerFile.status}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <strong>Size:</strong> {providerFile.size} bytes
                          </Typography>
                        </>
                      )
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      alignItems="right"
                      height="100%"
                      width={"100%"}
                    >
                      <Stack direction="column" alignItems="right" spacing={1}>
                        <Typography fontSize={18} fontWeight={700}>
                          Total Optimal Cost
                        </Typography>
                        <CircularProgress
                          size={100}
                          variant="determinate"
                          value={100}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                {/* END PLAN FILE DATA */}
                {/* PRICING AGGREGATIONS */}
                <Grid container spacing={2} mb={3} mt={2}>
                  <Grid item xs={2}>
                    <MedicareRatesAggregation
                      value={selectedRecord.medicare_data ?? []}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <NegotiatedRatesAggregation
                      value={selectedRecord.negotiated_rates}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TechnicalRatesAggregation
                      value={selectedRecord.negotiated_rates}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ProfessionalRatesAggregation
                      value={selectedRecord.negotiated_rates}
                    />
                  </Grid>
                </Grid>
                {/* END PRICING AGGREGATIONS */}

                <Typography
                  gutterBottom
                  fontWeight={800}
                  fontSize={14}
                  mb={2}
                  ml={1}
                >
                  Negotiated Prices
                </Typography>
                <NegotiatedPricesTable selectedRecord={selectedRecord} />
                {showMetadata && (
                  <div>
                    <Typography
                      gutterBottom
                      fontWeight={800}
                      fontSize={14}
                      mt={2}
                      mb={2}
                      ml={1}
                    >
                      Metadata
                    </Typography>
                    <pre style={{ fontSize: 12 }}>
                      {JSON.stringify(selectedRecord, null, 4)}
                    </pre>
                  </div>
                )}
                <Typography
                  gutterBottom
                  fontWeight={800}
                  fontSize={14}
                  mt={2}
                  mb={2}
                  ml={1}
                >
                  Reporting Plans
                </Typography>

                {!loadingSupplementalData ? (
                  providerFile?.reporting_plans && (
                    <ReportingPlanDrawerTable
                      reportingPlans={providerFile.reporting_plans}
                    />
                  )
                ) : (
                  <CircularProgress size={20} />
                )}
                {selectedRecord && selectedRecord.medicare_data && (
                  <>
                    <Typography
                      gutterBottom
                      fontWeight={800}
                      fontSize={14}
                      mt={2}
                      mb={2}
                      ml={1}
                    >
                      Medicare Details
                    </Typography>
                    <Card style={{ width: "98%" }}>
                      <CardContent>
                        <List></List>
                      </CardContent>
                    </Card>
                  </>
                )}
              </Paper>
            </div>
          </Box>
        )}
        {/* END MAIN */}
        {/* CHAT */}
        {chatDisplay && (
          <Paper
            elevation={3}
            style={{ padding: "45px 10px 10px 45px", minHeight: "80vh" }}
          >
            <PricingChatBot
              selectedRecord={selectedRecord}
              open={chatDisplay}
              onClose={showChat(false)}
            />
          </Paper>
        )}
        {/* END CHAT */}
      </Box>
    </Drawer>
  );
};

export default VeracityCodeDetailsDrawer;
