import * as React from "react";

import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { useDemoRouter } from "@toolpad/core/internal";
import { SearchProvider } from "../SearchContext";
import { AuthProvider, useAuth } from "../AuthContext";
// import { NAVIGATION } from "../components/MainNavLeftMenu";
import { AppTheme } from "../Theme";
// import { Search } from "../components/NavSearch";
import { InitialProvider } from "../InitialContext";
import SignInSide from "./sign-in-side/SignInSide";
import VeracityRoutes from "../routes/VeracityRoutes";
import { Search } from "../components/NavSearch";

interface DemoProps {
  window?: () => Window;
}

export function VeracityLayout(props: DemoProps) {
  const { window } = props;

  const router = useDemoRouter("");

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  const { isAuthenticated } = useAuth(); // Use the authentication context

  if (!isAuthenticated) {
    return <SignInSide />;
  }

  return (
    <InitialProvider>
      <SearchProvider>
        <AppProvider
          router={router}
          theme={AppTheme}
          window={demoWindow}
          branding={{
            logo: <img src="logo-veracity.png" alt="logo" />,
            title: "Veracity Benefits",
          }}
        >
          <DashboardLayout hideNavigation allowFullScreen loading={"lazy"}>
            {VeracityRoutes()}
          </DashboardLayout>
        </AppProvider>
      </SearchProvider>
    </InitialProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <SearchProvider>
        <VeracityLayout />
      </SearchProvider>
    </AuthProvider>
  );
}

export default App;
