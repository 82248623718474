import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { AuthUser, fetchAuthSession } from "aws-amplify/auth";

interface AuthContextProps {
  user: AuthUser | undefined;
  token: string | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Custom hook to use AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// AuthProvider component
export const AuthProvider: React.FC<{
  children: ReactNode;
  user: AuthUser | undefined;
}> = ({ children, user }) => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (user) {
        try {
          const session = await fetchAuthSession();
          let token = session.tokens?.idToken?.toString() || null;
          setToken(token);
        } catch (error) {
          console.error("Failed to fetch token:", error);
        }
      }
    };

    fetchToken();
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, token }}>
      {children}
    </AuthContext.Provider>
  );
};
