export const HomeHeaderSVG: React.FC = () => {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 1440 320"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
        display: "block",
        height: "160px",
      }}
    >
      <defs>
        <linearGradient id="themeGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#1976d2" />
          <stop offset="100%" stopColor="#1e88e5" />
        </linearGradient>
      </defs>
      <rect x="0" y="0" width="1440" height="320" fill="url(#themeGradient)" />
    </svg>
  );
};
