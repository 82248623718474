import React, { useEffect, useState } from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { HealthInsuranceProvider } from "../interfaces/Models";
import { Download, OpenInNew } from "@mui/icons-material";
import NavIconLink from "../components/NavIconLink";

const ColumnsConfig: GridColDef[] = [
  {
    field: "id",
    headerName: "",
    width: 75,
    maxWidth: 75,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "100%",
        }}
      >
        <NavIconLink
          to={`/insurance_provider/${params.value}`}
          icon={<Download fontSize="small" />}
        />
        <NavIconLink
          to={`/insurance_provider/${params.value}`}
          icon={<OpenInNew fontSize="small" />}
        />
      </Box>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    width: 300,
    maxWidth: 300,
  },
];

const HealthInsuranceProviderTable: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [providers, setProviders] = useState<HealthInsuranceProvider[] | null>(
    null
  );
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    setLoading(true);
    fetch(`${window.location.origin}/api/providers?page=1&per_page=10`)
      .then((response) => response.json())
      .then((data) => {
        setProviders(data);

        if (data.length > 0) {
          setColumns(ColumnsConfig);
        }

        const rows = data.map((item: HealthInsuranceProvider) => ({
          id: item.id,
          ...Object.fromEntries(
            Object.entries(item).filter(([key]) => key !== "id")
          ),
        }));
        setRows(rows);
        setLoading(false);
      });
  }, []);

  return (
    <Container maxWidth={false} disableGutters>
      <Box pl={2} pt={2} mb={2}>
        <Breadcrumbs>
          <Typography>Dashboard</Typography>
          <Typography>Insurance Carriers</Typography>
        </Breadcrumbs>
        {loading && (
          <Box mt={3}>
            <CircularProgress />
          </Box>
        )}
        {providers && (
          <Box
            sx={{
              width: "100%",
              maxWidth: "92vw",
              overflowX: "hidden",
              marginTop: "15px",
            }}
          >
            <DataGrid rows={rows} columns={columns} rowHeight={30} />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default HealthInsuranceProviderTable;
