import React, { createContext, useState, useContext, ReactNode } from "react";
import { ZipCode } from "./interfaces/ZipCode";
import { defaultFilters, Filters } from "./utils/SearchFilters";

interface SearchContextProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  searchHistory: string[];
  setSearchHistory: (history: string[]) => void;
  addSearchQuery: (query: string) => void;
  selectedZipCodes: ZipCode[];
  addSelectedZipCodes: (zipCode: ZipCode[]) => void;
  filters: Filters;
  setFilters: (filters: Filters) => void;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export const SearchProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filters, setSearchFilters] = useState<Filters>(defaultFilters);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [selectedZipCodes, setSelectedZipCodes] = useState<ZipCode[]>([]);

  const addSearchQuery = (query: string) => {
    setSearchQuery(query);
    setSearchHistory((prevHistory) => [...prevHistory, query]);
  };

  const setFilters = (filters: Filters) => {
    console.warn("Setting filters", filters);
    setSearchFilters(filters);
  };

  const addSelectedZipCodes = (zipCodes: ZipCode[]) => {
    setSelectedZipCodes(zipCodes);
  };

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        searchHistory,
        setSearchHistory,
        addSearchQuery,
        selectedZipCodes,
        addSelectedZipCodes,
        filters,
        setFilters,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = (): SearchContextProps => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};
