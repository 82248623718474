import React, { useEffect, useState, useCallback } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import { Container } from "@mui/material";
import {
  OpenSearchInNetworkRecord,
  OpenSearchInNetworkResponse,
} from "../../interfaces";
import { useSearch } from "../../SearchContext";
import debounce from "lodash.debounce";
import VeracityCodeDetailsDrawer from "../drawers/VeracityCodeDetailsDrawer";
import { ColumnsConfig } from "./VeracityInNetworkColumns";

const VeracityInNetworkTable: React.FC = () => {
  const { searchQuery, searchHistory, filters } = useSearch();
  const [records, setRecords] = useState<OpenSearchInNetworkRecord[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [rowCount, setRowCount] = useState(0);
  const [selectedRecord, setSelectedRecord] =
    useState<OpenSearchInNetworkRecord | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const fetchRecords = useCallback(
    debounce((page: number, pageSize: number, keyword: string) => {
      let apiUrl = `${window.location.origin}/api/search/in-network-v1/keyword_search?keyword=`;
      apiUrl += `${keyword}&page=${page + 1}&size=${pageSize}`;
      apiUrl += `&billing_code_types=${filters.billingCodeTypes.join(",")}`;
      apiUrl += `&billing_class_types=${filters.billingClassTypes.join(",")}`;
      apiUrl += `&negotiated_types=${filters.negotiatedTypes.join(",")}`;
      apiUrl += `&negotiation_arrangements=${filters.negotiationArrangements.join(",")}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data: OpenSearchInNetworkResponse) => {
          setRecords(data.results);
          setRowCount(data.total);

          // Set columns based on the keys of the first object in the response
          if (data.results.length > 0) {
            setColumns(ColumnsConfig);
          }

          // Set rows based on the response data
          const rows = data.results.map((item: OpenSearchInNetworkRecord) => ({
            id: item.uuid,
            negotiated_rates: item.negotiated_rates,
            technical_rates: item.negotiated_rates,
            professional_rates: item.negotiated_rates,
            ...Object.fromEntries(
              Object.entries(item).filter(([key]) => key !== "uuid")
            ),
          }));
          setRows(rows);
        });
    }, 300),
    []
  );

  useEffect(() => {
    fetchRecords(paginationModel.page, paginationModel.pageSize, searchQuery);
  }, [paginationModel, searchHistory, fetchRecords, filters]);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleRowClick = (params: GridRowParams) => {
    setSelectedRecord(params.row as OpenSearchInNetworkRecord);
    setDrawerOpen(true);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 600,
          marginTop: 15,
        }}
      >
        <DataGrid
          loading={records.length === 0}
          rows={rows}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          checkboxSelection={true}
          rowHeight={40}
          onRowClick={handleRowClick}
          columnVisibilityModel={{
            parent_file_uuid: false,
            description: false,
          }}
        />
      </div>

      <VeracityCodeDetailsDrawer
        selectedRecord={selectedRecord}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </Container>
  );
};

export default VeracityInNetworkTable;
