import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useAuth } from "../AuthContext";

interface ZipCode {
  zip: string;
  city: string;
  state_id: string;
  state_name: string;
  lat: number;
  lng: number;
  population: string;
  density: string;
  county_name: string;
  timezone: string;
  military: boolean;
}

const LocationSelector: React.FC = () => {
  const { token } = useAuth();
  const [zipCodes, setZipCodes] = useState<ZipCode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // const fetchZipCodes = async (query: string) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `/api/location/autocomplete?query=${query}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     setZipCodes(data);
  //   } catch (error) {
  //     console.error("Error fetching zip codes:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    if (value && value.length >= 3) {
      console.log("fetching zip codes for:", value);
      // fetchZipCodes(value);
    }
  };

  return (
    <Autocomplete
      style={{ width: "100%" }}
      size="small"
      options={zipCodes}
      getOptionLabel={(option) =>
        `${option.city}, ${option.state_id} - ${option.zip}`
      }
      filterSelectedOptions
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => option.zip === value.zip}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select Location"
          placeholder=""
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default LocationSelector;
