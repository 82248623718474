import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Breadcrumbs,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSearch } from "../SearchContext";
import { GoogleMapComponent } from "../components/maps/GoogleMapComponent";

interface Address {
  country_code: string;
  country_name: string;
  address_purpose: string;
  address_type: string;
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  postal_code: string;
  telephone_number: string;
  fax_number?: string;
}

interface BasicInfo {
  organization_name: string;
  organizational_subpart: string;
  enumeration_date: string;
  last_updated: string;
  certification_date: string;
  status: string;
  authorized_official_first_name: string;
  authorized_official_last_name: string;
  authorized_official_telephone_number: string;
  authorized_official_title_or_position: string;
  authorized_official_middle_name?: string;
  authorized_official_credential?: string;
  authorized_official_name_prefix?: string;
  authorized_official_name_suffix?: string;
}

interface Facility {
  id: string;
  created_epoch: string;
  enumeration_type: string;
  last_updated_epoch: string;
  number: string;
  addresses: Address[];
  basic: BasicInfo;
  practiceLocations: any[];
  taxonomies: any[];
  identifiers: any[];
  endpoints: any[];
  other_names: any[];
}

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "address_1", headerName: "Address", width: 150 },
  { field: "city", headerName: "City", width: 110 },
  { field: "state", headerName: "State", width: 110 },
  { field: "postal_code", headerName: "ZIP", width: 110 },
  { field: "telephone_number", headerName: "Phone", width: 150 },
];

const CareCenters: React.FC = () => {
  const { searchQuery, selectedZipCodes } = useSearch();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // DEBOUNCE
    fetch(
      `${window.location.origin}/api/nppes/facility?city=${searchQuery ? searchQuery : "Franklin"}&limit=${10}`
    )
      .then((response) => response.json())
      .then((data) => {
        setFacilities(data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [searchQuery]);

  return (
    <Container maxWidth={false} disableGutters>
      <Box pl={2} pt={2} mb={2}>
        <Breadcrumbs>
          <Typography>Dashboard</Typography>
          <Typography>Care Centers</Typography>
        </Breadcrumbs>
        {loading && (
          <Box mt={3}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <GoogleMapComponent
        apiKey={"AIzaSyCXGbUGi4KyI85-PzNNcky5MpBcG0_iHyU"}
        zoom={10}
        initialPosition={{ lat: 35.9250637, lng: -86.8688899 }}
        selectedZipCodes={selectedZipCodes}
      />
      <Box pl={2} pt={2} mb={2}>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          {facilities.slice(0, 3).map((facility) => (
            <Grid item xs={12} md={4} key={facility.number}>
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {facility.basic.organization_name}
                  </Typography>
                  {/* <Typography variant="body2" color="textSecondary">
                  {facility.addresses[0]?.address_1}
                </Typography> */}
                  <Typography variant="body2" color="textSecondary">
                    {facility.addresses[0]?.city},{" "}
                    {facility.addresses[0]?.state}{" "}
                    {facility.addresses[0]?.postal_code}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {facility.addresses[0]?.telephone_number}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box pl={2} sx={{ width: "100%", maxWidth: "92vw", overflowX: "hidden" }}>
        <DataGrid
          rows={facilities.map((facility) => ({
            id: facility.number,
            name: facility.basic.organization_name,
            address_1: facility.addresses[0]?.address_1,
            city: facility.addresses[0]?.city,
            state: facility.addresses[0]?.state,
            postal_code: facility.addresses[0]?.postal_code,
            telephone_number: facility.addresses[0]?.telephone_number,
          }))}
          columns={columns}
          // pageSize={5}
          loading={loading}
        />
      </Box>
    </Container>
  );
};

export default CareCenters;
