import React, { useState } from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Box, Typography, Tooltip } from "@mui/material";
import NavIconLink from "../NavIconLink";
import { Download, OpenInNew } from "@mui/icons-material";
import { ReportingPlan } from "../../interfaces/Models";

interface TableProps {
  reportingPlans: ReportingPlan[];
}

const ColumnsConfig: GridColDef[] = [
  { field: "plan_id", headerName: "Plan", width: 150, hideable: true },
  { field: "plan_id_type", headerName: "Plan ID Type", width: 150 },
  { field: "plan_name", headerName: "Plan Name", width: 350 },
  { field: "plan_market_type", headerName: "Plan Market Type", width: 150 },
];

const ReportingPlanDrawerTable: React.FC<TableProps> = ({ reportingPlans }) => {
  const [columns, setColumns] = useState<GridColDef[]>(ColumnsConfig);
  const [rows, setRows] = useState<GridRowsProp>(reportingPlans);

  return (
    <div style={{ height: 500, width: "98%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={reportingPlans.length}
        checkboxSelection={false}
        rowHeight={30}
      />
    </div>
  );
};

export default ReportingPlanDrawerTable;
