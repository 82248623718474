import { Container, Typography } from "@mui/material";
import React from "react";
import { HomeHeaderSVG } from "../../components/headers/HomeGeaderSVG";

const CartPage: React.FC = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <HomeHeaderSVG />
      <Typography variant="h4" gutterBottom>
        Cart
      </Typography>
      {/* SEARCH INPUT MOBILE*/}
      {/* FOOTER NAV */}
    </Container>
  );
};

export default CartPage;
