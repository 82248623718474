import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Divider,
  Grid,
  CircularProgress,
} from "@mui/material";
import { OpenSearchInNetworkRecord } from "../interfaces";

const CodeDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [code, setCode] = useState<OpenSearchInNetworkRecord | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`api/search/in-network-v1/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setCode(data);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!code) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6">Code not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Code Details • {code.name}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Billing Code:</strong> {code.billing_code}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Description:</strong> {code.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Billing Code Type:</strong> {code.billing_code_type}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Billing Code Type Version:</strong>{" "}
            {code.billing_code_type_version}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Negotiation Arrangement:</strong>{" "}
            {code.negotiation_arrangement}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>MD5 Checksum:</strong> {code.md5_checksum}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>UUID:</strong> {code.uuid}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Parent File UUID:</strong> {code.parent_file_uuid}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CodeDetails;
