import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/system";

const userTestimonials = [
  {
    avatar: <Avatar alt="" src="/static/images/avatar/1.jpg" />,
    name: "Cost Analysis",
    occupation: "Medical Imaging",
    testimonial:
      "Blue Cross Blue Shield has been a great partner for our medical imaging cost analysis. They have provided us with the data we need to make informed decisions about our imaging services.",
  },
  // {
  //   avatar: <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />,
  //   name: "Travis Howard",
  //   occupation: "Lead Product Designer",
  //   testimonial:
  //     "One of the standout features of this product is the exceptional customer support. In my experience, the team behind this product has been quick to respond and incredibly helpful. It's reassuring to know that they stand firmly behind their product.",
  // },
  // {
  //   avatar: <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />,
  //   name: "Cindy Baker",
  //   occupation: "CTO",
  //   testimonial:
  //     "The level of simplicity and user-friendliness in this product has significantly simplified my life. I appreciate the creators for delivering a solution that not only meets but exceeds user expectations.",
  // },
  // {
  //   avatar: <Avatar alt="Remy Sharp" src="/static/images/avatar/4.jpg" />,
  //   name: "Julia Stewart",
  //   occupation: "Senior Engineer",
  //   testimonial:
  //     "I appreciate the attention to detail in the design of this product. The small touches make a big difference, and it's evident that the creators focused on delivering a premium experience.",
  // },
  // {
  //   avatar: <Avatar alt="Travis Howard" src="/static/images/avatar/5.jpg" />,
  //   name: "John Smith",
  //   occupation: "Product Designer",
  //   testimonial:
  //     "I've tried other similar products, but this one stands out for its innovative features. It's clear that the makers put a lot of thought into creating a solution that truly addresses user needs.",
  // },
  // {
  //   avatar: <Avatar alt="Cindy Baker" src="/static/images/avatar/6.jpg" />,
  //   name: "Daniel Wolf",
  //   occupation: "CDO",
  //   testimonial:
  //     "The quality of this product exceeded my expectations. It's durable, well-designed, and built to last. Definitely worth the investment!",
  // },
];

const whiteLogos = [
  "bcbs-light.svg",
  "aetna-light.svg",
  "united-light.svg",
  "cigna-light.svg",
  "kaiser-light.svg",
  "humana-light.svg",
];

const darkLogos = [
  "bcbs.svg",
  "aetna.svg",
  "united.svg",
  "cigna.svg",
  "kaiser.svg",
  "humana.svg",
];

const logoStyle = {
  width: "64px",
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "text.primary" }}
        >
          White Papers
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          {/* See what our customers love about our products. Discover how we excel in
          efficiency, durability, and satisfaction. Join us for quality, innovation,
          and reliable support. */}
          Review our white papers to learn more about our products and services.
          Our white papers are designed to help you understand the benefits of
          our products and how they can help you achieve your goals.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid
            size={{ xs: 12, sm: 6, md: 4 }}
            key={index}
            sx={{ display: "flex" }}
          >
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: "text.secondary" }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
