import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  CircularProgress,
  Button,
} from "@mui/material";
import ReportingPlanTabPanel from "../components/ReportingPlanTabPanel";
import { ReportingPlan } from "../interfaces/Models";
import { useAuth } from "../AuthContext";

const ReportingPlanPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { token } = useAuth();
  const [plan, setPlan] = useState<ReportingPlan | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${window.location.origin}/api/reporting_plans/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPlan(data);
        setLoading(false);
      });
  }, [id, token]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!plan) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6">Plan not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        {plan.plan_name}
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          sx={{ ml: 2, float: "right" }}
        >
          Add to watchlist
        </Button>
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body1">
            <strong>Market Type:</strong> {plan.plan_market_type}
          </Typography>
          <Typography variant="body1">
            <strong>{plan.plan_id_type}</strong>: {plan.plan_id}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent></CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent></CardContent>
          </Card>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <ReportingPlanTabPanel reporting_plan={plan} />
      </Grid>
    </Box>
  );
};

export default ReportingPlanPage;
