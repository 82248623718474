import React, { useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import VeracityInNetworkTable from "../../components/tables/VeracityInNetworkTable";
import { BasicSearch } from "../../components/BasicSearch";
import { VeracitySearchFilters } from "../../components/drawers/VeracitySearchFilters";
import { VeracityExcelPlugin } from "../../components/dialog/VeracityExcelPlugin";
import Snackbar from "@mui/material/Snackbar";

const VeracityHome: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  return (
    <Container maxWidth={false} disableGutters>
      <Box pl={2} pt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Breadcrumbs>
              <Typography>Dashboard</Typography>
              <Typography>Veracity</Typography>
            </Breadcrumbs>
            <Typography fontSize={16} fontWeight={700} mt={2}>
              Overview
            </Typography>
          </Grid>
          <VeracitySearchFilters />
        </Grid>
      </Box>

      <Box
        ml={2}
        mt={4}
        sx={{ width: "100%", maxWidth: "97vw", overflowX: "hidden" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction="row">{BasicSearch()}</Stack>
          </Grid>
          <VeracityExcelPlugin />
        </Grid>
        <VeracityInNetworkTable />
      </Box>
      {/* SNACKBAR EXAMPLE */}
      {/* <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={open}
        transitionDuration={1000}
        autoHideDuration={1000}
        message="Setup your preferences"
      /> */}
    </Container>
  );
};

export default VeracityHome;
