import React, { useEffect, useState, useCallback } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Divider,
  Container,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import {
  Address,
  NppesApiResponse,
  OpenSearchInNetworkRecord,
  Provider,
} from "../../interfaces";
import { useSearch } from "../../SearchContext";
import debounce from "lodash.debounce";
import { Download, OpenInNew } from "@mui/icons-material";
import NavIconLink from "../NavIconLink";

const ColumnsConfig: GridColDef[] = [
  {
    field: "id",
    headerName: "",
    width: 75,
    maxWidth: 75,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "100%",
        }}
      >
        {/* <NavIconLink
          to={`/reporting_plans/${params.value}`}
          icon={<Download fontSize="small" />}
        />
        <NavIconLink
          to={`/reporting_plans/${params.value}`}
          icon={<OpenInNew fontSize="small" />}
        /> */}
      </Box>
    ),
  },
  { field: "number", headerName: "Number", width: 100 },
  { field: "enumeration_type", headerName: "Enumeration Type", width: 150 },
  {
    field: "basic",
    headerName: "Last Name",
    renderCell: (params) => <Box>{params.value.last_name}</Box>,
  },
  //   {
  //     field: "basic",
  //     headerName: "First Name",
  //     renderCell: (params) => <Box>{params.value.first_name}</Box>,
  //   },
  {
    field: "addresses",
    headerName: "Address",
    width: 300,
    renderCell: (params) => (
      <Box>
        {params.value.map((address: Address) => (
          <Box key={address.address_purpose}>
            {address.address_1}, {address.city}, {address.state}{" "}
          </Box>
        ))}
      </Box>
    ),
  },
];

const HealthProviderTable: React.FC = () => {
  const { searchQuery, searchHistory } = useSearch();
  const [records, setRecords] = useState<Provider[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 100,
  });
  const [rowCount, setRowCount] = useState(0);
  const [selectedRecord, setSelectedRecord] =
    useState<OpenSearchInNetworkRecord | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const fetchRecords = useCallback(
    debounce((page: number, pageSize: number, keyword: string) => {
      fetch(
        `${window.location.origin}/api/nppes/provider?name=${keyword}&page=${page + 1}&limit=${pageSize}`
      )
        .then((response) => response.json())
        .then((data: NppesApiResponse) => {
          setRecords(data.results);
          if (data.results.length > 0) {
            setColumns(ColumnsConfig);
          }

          const rows = data.results.map((item: Provider) => ({
            id: item.number,
            ...Object.fromEntries(
              Object.entries(item).filter(([key]) => key !== "number")
            ),
          }));
          setRows(rows);
        });
    }, 300),
    []
  );

  useEffect(() => {
    fetchRecords(paginationModel.page, paginationModel.pageSize, searchQuery);
  }, [paginationModel, searchHistory, fetchRecords]);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleRowClick = (params: GridRowParams) => {
    setSelectedRecord(params.row as OpenSearchInNetworkRecord);
    setDrawerOpen(true);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  return (
    <Container maxWidth={false} disableGutters>
      <div style={{ display: "flex", flexDirection: "column", height: 500 }}>
        <DataGrid
          loading={records.length === 0}
          rows={rows}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          checkboxSelection={true}
          rowHeight={40}
          onRowClick={handleRowClick}
          columnVisibilityModel={{
            parent_file_uuid: false,
            description: false,
          }}
        />
      </div>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 400,
            padding: 2,
          }}
          role="presentation"
        >
          <IconButton onClick={toggleDrawer(false)} sx={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" component="h2" gutterBottom>
            In-Network Record Details
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {selectedRecord && (
            <Box>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  UUID:
                </Typography>
                <Typography variant="body1">{selectedRecord.uuid}</Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  Name:
                </Typography>
                <Typography variant="body1">{selectedRecord.name}</Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  Type:
                </Typography>
                <Typography variant="body1">
                  {selectedRecord.billing_code_type}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  Market Type:
                </Typography>
                <Typography variant="body1">
                  {selectedRecord.billing_code_type_version}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  Billing Code:
                </Typography>
                <Typography variant="body1">
                  {selectedRecord.billing_code}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  Description:
                </Typography>
                <Typography variant="body1">
                  {selectedRecord.description}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  Negotiation Arrangement:
                </Typography>
                <Typography variant="body1">
                  {selectedRecord.negotiation_arrangement}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  MD5 Checksum:
                </Typography>
                <Typography variant="body1">
                  {selectedRecord.md5_checksum}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  Parent File UUID:
                </Typography>
                <Typography variant="body1">
                  {selectedRecord.parent_file_uuid}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h6" gutterBottom>
                  Negotiated Rates
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {selectedRecord.negotiated_rates.map((rate, index) => (
                  <Card key={index} sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="subtitle1" color="textSecondary">
                        Negotiated Type:
                      </Typography>
                      <Typography variant="body1">
                        {rate.negotiated_prices[0].negotiated_type}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Negotiated Rate:
                      </Typography>
                      <Typography variant="body1">
                        {rate.negotiated_prices[0].negotiated_rate}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Expiration Date:
                      </Typography>
                      <Typography variant="body1">
                        {rate.negotiated_prices[0].expiration_date}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Service Code:
                      </Typography>
                      {/* <Typography variant="body1">
                        {rate.negotiated_prices[0].service_code.join(", ")}
                      </Typography> */}
                      <Typography variant="subtitle1" color="textSecondary">
                        Billing Class:
                      </Typography>
                      <Typography variant="body1">
                        {rate.negotiated_prices[0].billing_class}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Billing Code Modifier:
                      </Typography>
                      {/* <Typography variant="body1">
                        {rate.negotiated_prices[0].billing_code_modifier.join(
                          ", "
                        )}
                      </Typography> */}
                      <Typography variant="subtitle1" color="textSecondary">
                        Additional Information:
                      </Typography>
                      <Typography variant="body1">
                        {rate.negotiated_prices[0].additional_information}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Drawer>
    </Container>
  );
};

export default HealthProviderTable;
