import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import {
  ZipCode,
  ReportingPlan,
  HealthInsuranceProvider,
} from "./interfaces/Models";
interface ViewResponse {
  zip_codes: ZipCode[];
  health_insurance_providers: HealthInsuranceProvider[];
  reporting_plans: ReportingPlan[];
}
interface InitialContextProps {
  initialData: ViewResponse | null;
  loading: boolean;
  error: string | null;
  preferences: Record<string, any>;
  setPreferences: (prefs: Record<string, any>) => void;
}

const InitialContext = createContext<InitialContextProps | undefined>(
  undefined
);

export const InitialProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [initialData, setInitialData] = useState<ViewResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [preferences, setPreferencesState] = useState<Record<string, any>>(
    () => {
      const savedPreferences = sessionStorage.getItem("preferences");
      return savedPreferences ? JSON.parse(savedPreferences) : undefined;
    }
  );

  const setPreferences = (prefs: Record<string, any>) => {
    setPreferencesState(prefs);
    sessionStorage.setItem("preferences", JSON.stringify(prefs));
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        let api_path;
        if (!preferences) {
          api_path = `${window.location.origin}/api/basic_view`;
        } else {
          api_path = `${window.location.origin}/api/user_preferences_view?prefs=${JSON.stringify(preferences)}`;
        }
        const response = await fetch(api_path);
        const data = await response.json();
        setInitialData(data);
      } catch (err) {
        setError("Failed to fetch initial data");
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [preferences]);

  return (
    <InitialContext.Provider
      value={{ initialData, loading, error, preferences, setPreferences }}
    >
      {children}
    </InitialContext.Provider>
  );
};

export const useInitial = (): InitialContextProps => {
  const context = useContext(InitialContext);
  if (!context) {
    throw new Error("useInitial must be used within an InitialProvider");
  }
  return context;
};
